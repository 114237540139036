import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.tsx'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './index.css'
import './styles/fonts.css'
import './styles/font-override.css'
import './styles/hero-title.css'
import './styles/cards.css'

// Inicializa o AOS
AOS.init({
  duration: 800,
  offset: 100,
  once: true
})

// Função para garantir que os cards da equipe sejam exibidos corretamente
window.addEventListener('load', () => {
  setTimeout(() => {
    console.log('Verificando exibição dos cards após carregamento completo');
    
    // Verificar se os cards estão visíveis
    const teamMembers = document.querySelectorAll('.team-member');
    console.log(`Cards encontrados: ${teamMembers.length}`);
    
    if (teamMembers.length === 0) {
      console.log('Nenhum card encontrado, forçando exibição da seção');
      
      // Verificar se o container da equipe existe
      const teamContainer = document.querySelector('.team-grid');
      if (teamContainer) {
        console.log('Container da equipe encontrado, mas sem cards');
        
        // Forçar atualização de CSS
        document.querySelectorAll('.team-members').forEach(el => {
          (el as HTMLElement).style.display = 'grid';
          (el as HTMLElement).style.visibility = 'visible';
          (el as HTMLElement).style.opacity = '1';
        });
      }
    }
    
    // Verificar se os cards estão com o efeito de flip aplicado
    const cards = document.querySelectorAll('.team-member');
    cards.forEach(card => {
      const inner = card.querySelector('.team-card-inner');
      if (!inner) return;
      
      // Garantir que o elemento esteja visível
      (card as HTMLElement).style.visibility = 'visible';
      (card as HTMLElement).style.display = 'block';
      
      // Verificar se estamos em desktop ou mobile
      const isMobile = window.innerWidth <= 768;
      
      if (!isMobile) {
        // Desktop: aplicar hover
        card.addEventListener('mouseenter', () => {
          (inner as HTMLElement).style.transform = 'rotateY(180deg)';
        });
        
        card.addEventListener('mouseleave', () => {
          (inner as HTMLElement).style.transform = 'rotateY(0deg)';
        });
      }
    });
  }, 2000);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
