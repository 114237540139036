import { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../styles/cards.css'

const Hero = () => {
  const [isSpecialWidth, setIsSpecialWidth] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    })
    
    // Função para verificar a largura da tela
    const checkWidth = () => {
      const width = window.innerWidth;
      setIsSpecialWidth(width >= 1292 && width <= 1544);
    };
    
    // Verificar inicialmente
    checkWidth();
    
    // Adicionar listener para redimensionamento
    window.addEventListener('resize', checkWidth);
    
    // Limpar listener
    return () => window.removeEventListener('resize', checkWidth);
  }, [])

  return (
    <section id="home" className="hero">
      <div className="hero-bg">
        <video className="hero-video" autoPlay muted loop playsInline>
          <source src="/vecteezy_customer-service-good-cooperation-consultation-between-a_33486313.MOV" type="video/mp4" />
        </video>
        <div className="hero-overlay"></div>
      </div>
      <div className="hero-particles">
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>
      <div className="hero-content">
        <h1 data-aos="fade-up" data-aos-delay="100">
          Transformando desafios legais em soluções
          <span className="eficazes-text" style={{ marginBottom: '30px' }}>EFICAZES</span>
        </h1>
        
        <div className="mobile-spacer" style={{ height: '30px', display: 'block' }}></div>
 
        <div 
          className={`cta-buttons mobile-spacing ${isSpecialWidth ? 'vertical-buttons' : ''}`}
          data-aos="fade-up" 
          data-aos-delay="300"
          style={{ marginTop: '30px' }}
        >
          <a href="https://wa.me/5527988332711" className="btn btn-primary">
            <i className="ri-whatsapp-line"></i>
            <span className="btn-text">Fale com um Advogado</span>
          </a>
          <a href="#services" className="btn btn-secondary">
            <i className="ri-arrow-right-line"></i>
            <span className="btn-text">Conheça Nossa Atuação</span>
          </a>
        </div>
      </div>
      <div className="hero-visual">
        <div className="hero-cards">
          <div className="hero-card expertise-card" data-aos="fade-left" data-aos-delay="200">
            <i className="ri-scales-line"></i>
            <h3>Expertise Jurídica</h3>
            <p>Conhecimento aprofundado em diversas áreas do direito</p>
          </div>
          <div className="hero-card compromisso-card" data-aos="fade-left" data-aos-delay="400">
            <i className="ri-shield-star-line"></i>
            <h3>Compromisso Total</h3>
            <p>Dedicação exclusiva em cada caso</p>
          </div>
          <div className="hero-card atendimento-card" data-aos="fade-left" data-aos-delay="600">
            <i className="ri-heart-2-line"></i>
            <h3>Atendimento Personalizado</h3>
            <p>Acompanhamento personalizado para cada cliente</p>
          </div>
          <div className="hero-card equipe-card" data-aos="fade-left" data-aos-delay="800">
            <i className="ri-trophy-line"></i>
            <h3>Equipe sempre atualizada</h3>
            <p>Nossa equipe está sempre se atualizando com as mudanças da lei</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
