import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBqW3oHpA0gHhII7X0W48JIkCF_LmXws98",
  authDomain: "carneiroesantos-d156f.firebaseapp.com",
  projectId: "carneiroesantos-d156f",
  storageBucket: "carneiroesantos-d156f.firebasestorage.app",
  messagingSenderId: "746302958966",
  appId: "1:746302958966:web:1ccdf42acb94022ec3de57"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

// Enable offline persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a time.
      console.log('Persistence failed: Multiple tabs open');
    } else if (err.code === 'unimplemented') {
      // The current browser doesn't support persistence
      console.log('Persistence not supported by browser');
    }
  });

export default app;
