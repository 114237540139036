import { useEffect, useRef, forwardRef } from 'react';
import '../styles/HugerteEditor.css';

// Declare a interface para a janela global
declare global {
  interface Window {
    tinymce: any;
  }
}

// Caminho para o script do TinyMCE via CDN (usado como substituto do Hugerte)
const TINYMCE_SCRIPT_SRC = 'https://cdn.jsdelivr.net/npm/tinymce@6.7.0/tinymce.min.js';

// Variável para controlar se o TinyMCE já foi carregado
let tinymceLoaded = false;

// Interface para as propriedades do editor
interface HugerteEditorProps {
  value?: string;
  onEditorChange?: (content: string) => void;
  init?: Record<string, any>;
  disabled?: boolean;
  id?: string;
}

/**
 * Componente para integrar o TinyMCE com React (como substituto do Hugerte)
 */
const HugerteEditor = forwardRef<any, HugerteEditorProps>(({ 
  value, 
  onEditorChange, 
  init = {}, 
  disabled = false,
  id = 'hugerte-editor'
}, ref) => {
  const editorRef = useRef<any>(null);
  const elementRef = useRef<HTMLTextAreaElement>(null);
  const valueRef = useRef<string | undefined>(value);
  const initializedRef = useRef<boolean>(false);
  const onChangeRef = useRef(onEditorChange);
  const contentChangedByExternalUpdate = useRef<boolean>(false);
  const loadingRef = useRef<boolean>(false);
  const instanceIdRef = useRef<string>(`${id}-${Date.now()}`); // ID único para cada instância

  // Expor o editor para o componente pai através da ref
  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(editorRef.current);
      } else {
        ref.current = editorRef.current;
      }
    }
  }, [ref, editorRef.current]);

  // Atualizar as referências quando as props mudarem
  useEffect(() => {
    valueRef.current = value;
    onChangeRef.current = onEditorChange;
  }, [value, onEditorChange]);

  // Carregar o script do TinyMCE
  useEffect(() => {
    if (window.tinymce && tinymceLoaded) {
      // TinyMCE já está carregado, inicializar o editor
      if (!initializedRef.current) {
        initializeEditor();
      }
      return;
    }

    if (loadingRef.current) {
      return; // Já está carregando
    }

    loadingRef.current = true;
    console.log('Iniciando carregamento do TinyMCE...');
    
    // Carregar o script principal
    const script = document.createElement('script');
    script.src = TINYMCE_SCRIPT_SRC;
    script.async = true;
    script.onload = () => {
      console.log('TinyMCE carregado com sucesso');
      
      loadingRef.current = false;
      tinymceLoaded = true;
      
      // Inicializar o editor se o componente ainda estiver montado
      if (elementRef.current && !initializedRef.current) {
        initializeEditor();
      }
    };
    script.onerror = (error) => {
      console.error('Erro ao carregar TinyMCE:', error);
      loadingRef.current = false;
    };
    document.body.appendChild(script);

    return () => {
      // Não remover o script quando o componente for desmontado
      // para evitar recarregamento desnecessário
    };
  }, []);

  // Função para inicializar o editor
  const initializeEditor = () => {
    if (!window.tinymce || !elementRef.current || initializedRef.current) {
      return;
    }

    console.log('Inicializando editor TinyMCE...', instanceIdRef.current);

    try {
      // Verificar se o tema atual é escuro
      const isDarkTheme = document.documentElement.getAttribute('data-theme') === 'dark';
      
      // Configurações padrão do editor
      const defaultSettings = {
        height: 400,
        menubar: true,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'link | removeformat | help',
        content_style: isDarkTheme 
          ? 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #1a1a1a !important; color: #ffffff !important; } a { color: #6a8bdd !important; text-decoration: underline !important; }' 
          : 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; } a { text-decoration: underline !important; }',
        skin: isDarkTheme ? 'oxide-dark' : 'oxide',
        link_assume_external_targets: true,
        link_default_target: '_blank',
        link_default_protocol: 'https',
        convert_urls: false, // Impedir que o TinyMCE modifique as URLs
        allow_html_in_named_anchor: true // Permitir HTML dentro de âncoras nomeadas
      };

      // Mesclar as configurações padrão com as configurações personalizadas
      const settings = {
        ...defaultSettings,
        ...init,
        selector: `#${instanceIdRef.current}`,
        readonly: disabled,
        setup: (editor: any) => {
          editorRef.current = editor;
          
          editor.on('init', () => {
            initializedRef.current = true;
            if (valueRef.current) {
              contentChangedByExternalUpdate.current = true;
              editor.setContent(valueRef.current);
            }
            
            // Aplicar estilos adicionais para o tema escuro
            if (isDarkTheme) {
              // Forçar o tema escuro no iframe do editor
              try {
                const iframe = editor.getContentAreaContainer().querySelector('iframe');
                if (iframe && iframe.contentDocument) {
                  const iframeDoc = iframe.contentDocument;
                  const style = iframeDoc.createElement('style');
                  style.textContent = `
                    body {
                      background-color: #1a1a1a !important;
                      color: #ffffff !important;
                    }
                    p, div, span, h1, h2, h3, h4, h5, h6, li, td, th {
                      color: #ffffff !important;
                    }
                    a {
                      color: #6a8bdd !important;
                    }
                  `;
                  iframeDoc.head.appendChild(style);
                }
              } catch (error) {
                console.error('Erro ao aplicar estilos escuros ao iframe:', error);
              }
            }
          });

          // Usar um debounce para evitar atualizações muito frequentes
          let debounceTimer: number | null = null;
          
          // Capturar eventos de mudança de conteúdo
          const handleContentChange = () => {
            if (contentChangedByExternalUpdate.current) {
              contentChangedByExternalUpdate.current = false;
              return;
            }
            
            if (debounceTimer) {
              clearTimeout(debounceTimer);
            }
            
            debounceTimer = window.setTimeout(() => {
              try {
                const content = editor.getContent();
                if (onChangeRef.current && content !== valueRef.current) {
                  onChangeRef.current(content);
                }
              } catch (error) {
                console.error('Erro ao obter conteúdo do editor:', error);
              }
            }, 100);
          };

          // Registrar eventos de mudança
          editor.on('Change', handleContentChange);
          editor.on('KeyUp', handleContentChange);
          editor.on('Paste', handleContentChange);
          
          // Preservar o foco ao clicar em botões da barra de ferramentas
          editor.on('click', (e: MouseEvent) => {
            e.stopPropagation();
          });

          // Chamar a função setup personalizada, se fornecida
          if (init.setup) {
            init.setup(editor);
          }
        }
      };

      // Inicializar o editor
      window.tinymce.init(settings);
    } catch (error) {
      console.error('Erro ao inicializar o TinyMCE:', error);
    }
  };

  // Inicializar o editor quando o componente for montado
  useEffect(() => {
    // Se o TinyMCE já estiver disponível, inicializar o editor
    if (window.tinymce && tinymceLoaded && !initializedRef.current) {
      initializeEditor();
    }

    return () => {
      // Remover o editor quando o componente for desmontado
      if (editorRef.current) {
        try {
          console.log('Removendo editor TinyMCE...', instanceIdRef.current);
          window.tinymce.remove(`#${instanceIdRef.current}`);
          initializedRef.current = false;
          editorRef.current = null;
        } catch (error) {
          console.error('Erro ao remover o editor:', error);
        }
      }
    };
  }, [id, disabled]);

  // Atualizar o conteúdo do editor quando o valor mudar externamente
  useEffect(() => {
    if (editorRef.current && initializedRef.current && value !== undefined) {
      try {
        const currentContent = editorRef.current.getContent();
        if (value !== currentContent) {
          contentChangedByExternalUpdate.current = true;
          editorRef.current.setContent(value);
        }
      } catch (error) {
        console.error('Erro ao atualizar conteúdo do editor:', error);
      }
    }
  }, [value]);

  // Atualizar as configurações do editor quando elas mudarem
  useEffect(() => {
    if (editorRef.current && initializedRef.current) {
      try {
        // Atualizar o modo somente leitura
        editorRef.current.mode.set(disabled ? 'readonly' : 'design');
      } catch (error) {
        console.error('Erro ao atualizar modo do editor:', error);
      }
    }
  }, [disabled]);

  // Efeito para detectar mudanças no tema e reinicializar o editor
  useEffect(() => {
    // Função para verificar mudanças no tema
    const handleThemeChange = () => {
      if (initializedRef.current && editorRef.current) {
        // Salvar o conteúdo atual
        const currentContent = editorRef.current.getContent();
        
        // Remover o editor atual
        window.tinymce.remove(`#${instanceIdRef.current}`);
        initializedRef.current = false;
        
        // Reinicializar o editor com o novo tema
        setTimeout(() => {
          if (elementRef.current) {
            initializeEditor();
            
            // Restaurar o conteúdo após a reinicialização
            setTimeout(() => {
              if (editorRef.current) {
                editorRef.current.setContent(currentContent);
              }
            }, 100);
          }
        }, 100);
      }
    };

    // Observar mudanças no atributo data-theme do documento
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-theme'
        ) {
          handleThemeChange();
        }
      });
    });

    // Iniciar a observação
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme']
    });

    // Limpar o observador quando o componente for desmontado
    return () => {
      observer.disconnect();
    };
  }, []);

  // Renderizar um textarea que será substituído pelo TinyMCE
  // Usando um ID único para cada instância para evitar conflitos
  return <textarea id={instanceIdRef.current} ref={elementRef} defaultValue={value} style={{ visibility: 'hidden' }} />;
});

// Função para limpar completamente o TinyMCE (pode ser chamada externamente)
export const resetHugerte = () => {
  if (window.tinymce) {
    try {
      window.tinymce.remove();
      console.log('TinyMCE removido completamente');
    } catch (error) {
      console.error('Erro ao remover TinyMCE:', error);
    }
  }
};

export default HugerteEditor; 