import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import WhatsAppButton from '../../components/WhatsAppButton';
import { WordPressService } from '../../services/wordpressApi';
import '../../styles/servicePage.css';

interface PageContent {
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

export default function ImobiliarioPage() {
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState<PageContent | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadContent();
  }, []);

  const loadContent = async () => {
    try {
      const content = await WordPressService.getPage('imobiliario');
      setPageContent(content);
    } catch (error) {
      console.error('Erro ao carregar conteúdo:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para decodificar HTML entities
  const decodeHtmlEntities = (str: string) => {
    const element = document.createElement('div');
    if(str && typeof str === 'string') {
      element.innerHTML = str;
      return element.textContent;
    }
    return str;
  };

  // Componente do CTA que será sempre o mesmo
  const CTASection = () => (
    <div className="cta-section">
      <h2>Precisa de Assessoria Imobiliária?</h2>
      <p>
        Entre em contato para uma consultoria especializada em seu caso.
      </p>
      <a href="https://api.whatsapp.com/send?phone=5527988332711&text=Ol%C3%A1,%20gostaria%20de%20agendar%20um%20atendimento." className="cta-button">Fale Conosco</a>
    </div>
  );

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="app">
      <WhatsAppButton />
      <div className="service-page">
        <div className="service-content">
          <button className="back-button" onClick={() => navigate(-1)}>
            <i className="ri-arrow-left-line"></i> Voltar
          </button>
          
          {pageContent ? (
            <>
              <h1>{decodeHtmlEntities(pageContent.title.rendered)}</h1>
              <div 
                className="service-description"
                dangerouslySetInnerHTML={{ __html: pageContent.content.rendered }}
              />
              <CTASection />
            </>
          ) : (
            // Conteúdo fallback caso não carregue do WordPress
            <div className="service-description">
              <h1>Direito Imobiliário</h1>
              <p>
                Nossa equipe especializada em direito imobiliário oferece suporte completo 
                em todas as questões relacionadas a imóveis, garantindo segurança jurídica 
                nas transações e resolução eficiente de conflitos.
              </p>

              <h2>Áreas de Atuação:</h2>
              <ul>
                <li>Compra e venda de imóveis</li>
                <li>Locação e administração</li>
                <li>Contratos imobiliários</li>
                <li>Incorporação imobiliária</li>
                <li>Regularização de imóveis</li>
                <li>Usucapião</li>
              </ul>

              <h2>Serviços Oferecidos:</h2>
              <ul>
                <li>Análise de documentação</li>
                <li>Due diligence imobiliária</li>
                <li>Assessoria em financiamentos</li>
                <li>Ações possessórias</li>
                <li>Mediação de conflitos</li>
              </ul>

              <CTASection />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
} 