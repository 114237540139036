import React, { useState, useCallback, useEffect } from 'react';
import '../styles/clientArea.css';

const CLIENT_LOGIN_URL = 'https://www.siteadv.com.br/carneirosantosadv/ExternalLogin.aspx?id=10145';

interface ClientAreaProps {
  isModalOpenExternal?: boolean;
  onCloseModalExternal?: () => void;
}

const ClientArea: React.FC<ClientAreaProps> = ({ 
  isModalOpenExternal = false, 
  onCloseModalExternal 
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);

  // Sync external modal state
  useEffect(() => {
    if (isModalOpenExternal) {
      setIsModalOpen(true);
    }
  }, [isModalOpenExternal]);

  // Verificar estado de login ao carregar
  useEffect(() => {
    const loginStatus = localStorage.getItem('isClientLoggedIn');
    setIsLoggedIn(loginStatus === 'true');
  }, []);

  const openModal = useCallback(() => {
    if (isLoggedIn) {
      // Se já estiver logado, redirecionar direto para o site do cliente
      window.location.href = CLIENT_LOGIN_URL;
    } else {
      setIsModalOpen(true);
      setLoginError(false); // Resetar erro ao abrir o modal
    }
  }, [isLoggedIn]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setLoginError(false);
    
    // Call external close handler if provided
    if (onCloseModalExternal) {
      onCloseModalExternal();
    }
  }, [onCloseModalExternal]);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    const loginInput = e.currentTarget.querySelector('input[name="login"]') as HTMLInputElement;
    const senhaInput = e.currentTarget.querySelector('input[name="senha"]') as HTMLInputElement;

    if (loginInput.value && senhaInput.value) {
      // Se os campos estiverem preenchidos, submete o formulário
      e.currentTarget.submit();
    } else {
      e.preventDefault();
      setLoginError(true);
    }
  };

  return (
    <>
      <a href="#" className="nav-link client-area" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation(); // Impede que o evento chegue no handler do Navbar
        openModal();
      }}>
        Área do Cliente
      </a>

      {isModalOpen && (
        <div className="modal-overlay" onClick={(e) => {
          if (e.target === e.currentTarget) {
            closeModal();
          }
        }}>
          <div 
            className="modal-content" 
          >
            <button className="close-modal" onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}>
              &times;
            </button>
            <h2>Área do Cliente</h2>
            {loginError && (
              <div className="login-error">
                Preencha Login e Senha corretamente.
              </div>
            )}
            <form 
              name='form' 
              method='post' 
              action={CLIENT_LOGIN_URL}
              onSubmit={handleLogin}
            >
              <div className="form-group">
                <label>Login</label>
                <input 
                  type='text' 
                  name='login' 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Senha</label>
                <input 
                  type='password' 
                  name='senha' 
                  required 
                />
              </div>
              <button 
                type="submit" 
                className="login-button"
              >
                Entrar
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientArea;
