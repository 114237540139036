import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import WhatsAppButton from '../../components/WhatsAppButton';
import { WordPressService } from '../../services/wordpressApi';
import '../../styles/servicePage.css';

interface PageContent {
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

export default function FamiliaPage() {
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState<PageContent | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadContent();
  }, []);

  const loadContent = async () => {
    try {
      const content = await WordPressService.getPage('familia');
      setPageContent(content);
    } catch (error) {
      console.error('Erro ao carregar conteúdo:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para decodificar HTML entities
  const decodeHtmlEntities = (str: string) => {
    const element = document.createElement('div');
    if(str && typeof str === 'string') {
      element.innerHTML = str;
      return element.textContent;
    }
    return str;
  };

  // Componente do CTA que será sempre o mesmo
  const CTASection = () => (
    <div className="cta-section">
      <h2>Precisa de Orientação em Questões Familiares?</h2>
      <p>
        Entre em contato para um atendimento personalizado e confidencial.
      </p>
      <a href="https://api.whatsapp.com/send?phone=5527988332711&text=Ol%C3%A1,%20gostaria%20de%20agendar%20um%20atendimento." className="cta-button">Fale Conosco</a>
    </div>
  );

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="app">
      <WhatsAppButton />
      <div className="service-page">
        <div className="service-content">
          <button className="back-button" onClick={() => navigate(-1)}>
            <i className="ri-arrow-left-line"></i> Voltar
          </button>
          
          {pageContent ? (
            <>
              <h1>{decodeHtmlEntities(pageContent.title.rendered)}</h1>
              <div 
                className="service-description"
                dangerouslySetInnerHTML={{ __html: pageContent.content.rendered }}
              />
              <CTASection />
            </>
          ) : (
            // Conteúdo fallback caso não carregue do WordPress
            <div className="service-description">
              <h1>Família e Sucessões</h1>
              <p>
                Oferecemos suporte jurídico especializado em questões familiares e sucessórias, 
                com sensibilidade e profissionalismo para lidar com momentos delicados e 
                importantes da vida familiar.
              </p>

              <h2>Direito de Família:</h2>
              <ul>
                <li>Divórcio e separação</li>
                <li>Guarda e visitação</li>
                <li>Pensão alimentícia</li>
                <li>União estável</li>
                <li>Adoção</li>
                <li>Alteração de regime de bens</li>
              </ul>

              <h2>Direito Sucessório:</h2>
              <ul>
                <li>Inventário e partilha</li>
                <li>Testamentos</li>
                <li>Planejamento sucessório</li>
                <li>Herança e legados</li>
                <li>Disputas hereditárias</li>
              </ul>

              <CTASection />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
} 