import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  collection, 
  addDoc, 
  getDocs, 
  deleteDoc, 
  doc, 
  updateDoc 
} from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/adminDashboard.css';
import HugerteEditor from './HugerteEditor';

interface Post {
  id?: string;
  title: string;
  content: string;
  author: string;
  date: Date | { seconds: number; nanoseconds: number };
}

const AdminDashboard: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('Escritório Jurídico');
  const [postDate, setPostDate] = useState<string>(formatDateForInput(new Date()));
  const [editingPost, setEditingPost] = useState<Post | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);

  // Função para formatar data para o campo input type="date"
  function formatDateForInput(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Função para converter string de data para objeto Date
  function parseInputDate(dateString: string): Date {
    return new Date(dateString + 'T12:00:00'); // Midday to avoid timezone issues
  }

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'posts'));
      const fetchedPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Post));
      console.log('Fetched Posts:', fetchedPosts);
      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Erro ao buscar posts:', error);
    }
  };

  const handleLogout = () => {
    // Remover autenticação do localStorage
    localStorage.removeItem('isAuthenticated');
    navigate('/admin');
  };

  const sanitizeContent = (text: string) => {
    // Substituir quebras de linha por marcador de parágrafo
    return text.replace(/\n/g, '||PARAGRAPH||');
  };

  const desanitizeContent = (text: string) => {
    // Reverter marcador de parágrafo para quebras de linha
    return text.replace(/\|\|PARAGRAPH\|\|/g, '\n');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Obter o conteúdo atualizado do editor
    const editorContent = editorRef.current ? editorRef.current.getContent() : content;
    const sanitizedContent = sanitizeContent(editorContent);

    // Converter a string de data para objeto Date
    const dateObject = parseInputDate(postDate);

    const postData: Omit<Post, 'id'> = {
      title,
      content: sanitizedContent,
      author,
      date: dateObject
    };

    console.log('Saving Post:', postData);

    try {
      if (editingPost) {
        const postRef = doc(db, 'posts', editingPost.id!);
        await updateDoc(postRef, postData);
      } else {
        await addDoc(collection(db, 'posts'), postData);
      }

      // Limpar formulário após salvar
      resetForm();
      fetchPosts();
    } catch (error) {
      console.error('Erro ao salvar post:', error);
    }
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setAuthor('Escritório Jurídico');
    setPostDate(formatDateForInput(new Date()));
    setEditingPost(null);
  };

  const handleEdit = (post: Post) => {
    setEditingPost(post);
    setTitle(post.title);
    setContent(desanitizeContent(post.content));
    
    // Configurar autor do post
    if (post.author) {
      setAuthor(post.author);
    }
    
    // Configurar data do post
    let postDateObj: Date;
    if (post.date instanceof Date) {
      postDateObj = post.date;
    } else if (typeof post.date === 'object' && post.date.seconds) {
      postDateObj = new Date((post.date as any).seconds * 1000);
    } else {
      postDateObj = new Date();
    }
    setPostDate(formatDateForInput(postDateObj));
  };

  const handleDelete = async (postId: string) => {
    if (window.confirm('Tem certeza que deseja excluir este post?')) {
      try {
        await deleteDoc(doc(db, 'posts', postId));
        fetchPosts();
      } catch (error) {
        console.error('Erro ao excluir post:', error);
      }
    }
  };

  const handleEditorChange = (content: string) => {
    setContent(content);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const formatPostDate = (postDate: any): string => {
    let date: Date;
    
    if (postDate instanceof Date) {
      date = postDate;
    } else if (typeof postDate === 'object' && postDate.seconds) {
      date = new Date(postDate.seconds * 1000);
    } else {
      date = new Date();
    }
    
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  // Função para converter conteúdo HTML para texto simples para preview
  const getTextFromHtml = (html: string): string => {
    // Cria um elemento temporário
    const tempElement = document.createElement('div');
    
    // Atribui o HTML ao elemento (isso vai interpretar o HTML)
    tempElement.innerHTML = html.replace(/\|\|PARAGRAPH\|\|/g, '\n');
    
    // Retorna o texto sem formatação
    return tempElement.textContent || tempElement.innerText || '';
  };

  // Função para renderizar o conteúdo HTML de forma segura
  const renderContentPreview = (content: string) => {
    // Substituir marcadores de parágrafo por espaços para evitar quebras extras
    const htmlContent = content.replace(/\|\|PARAGRAPH\|\|/g, ' ');
    
    // Limitar o tamanho do conteúdo para a prévia
    const previewContent = htmlContent.length > 200 
      ? htmlContent.substring(0, 200) + '...' 
      : htmlContent;
    
    return (
      <div className="post-content-preview">
        <div className="content-preview" dangerouslySetInnerHTML={{ __html: previewContent }} />
      </div>
    );
  };

  // Filtra os posts com base no termo de pesquisa
  const filteredPosts = posts.filter(post => {
    if (!searchTerm.trim()) return true;
    
    const searchTermLower = searchTerm.toLowerCase();
    const titleMatch = post.title.toLowerCase().includes(searchTermLower);
    const contentMatch = getTextFromHtml(post.content).toLowerCase().includes(searchTermLower);
    
    return titleMatch || contentMatch;
  });

  // Função reservada para uso futuro
  // @ts-expect-error - Função será utilizada no futuro
  const __renderItemImages = (_images: string) => {
    // ... existing code ...
  };

  return (
    <div className="admin-dashboard-container">
      <div className="admin-dashboard">
        <div className="dashboard-header">
          <h1>Painel Administrativo</h1>
          <button 
            className="logout-button" 
            onClick={handleLogout}
          >
            Sair
          </button>
        </div>
        
        <div className="dashboard-content">
          <form onSubmit={handleSubmit} className="post-form">
            <h2>{editingPost ? 'Editar Post' : 'Novo Post'}</h2>
            
            <div className="form-group">
              <label htmlFor="post-title">Título</label>
              <input
                id="post-title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                className="title-input"
                placeholder="Digite o título do post"
              />
            </div>

            <div className="form-meta">
              <div className="form-group author-field">
                <label htmlFor="post-author">Autor</label>
                <input
                  id="post-author"
                  type="text"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="author-input"
                  placeholder="Nome do autor"
                />
              </div>
              
              <div className="form-group date-field">
                <label htmlFor="post-date">Data</label>
                <input
                  id="post-date"
                  type="date"
                  value={postDate}
                  onChange={(e) => setPostDate(e.target.value)}
                  className="date-input"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="post-content">Conteúdo</label>
              <div className="editor-container">
                <HugerteEditor
                  value={content}
                  onEditorChange={handleEditorChange}
                  ref={editorRef}
                  init={{
                    height: 300,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
              </div>
              <small className="editor-tip">
                Utilize as ferramentas do editor para formatar o texto e adicionar links.
              </small>
            </div>

            <div className="form-actions">
              <button 
                type="submit" 
                className="submit-button"
              >
                {editingPost ? 'Atualizar' : 'Publicar'}
              </button>
              {editingPost && (
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={resetForm}
                >
                  Cancelar
                </button>
              )}
            </div>
          </form>

          <div className="posts-list">
            <div className="posts-header">
              <h2>Posts Publicados ({posts.length})</h2>
              <div className="posts-search-container">
                <span className="search-icon">🔍</span>
                <input
                  type="text"
                  className="posts-search"
                  placeholder="Pesquisar posts..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
            
            {posts.length === 0 ? (
              <div className="empty-posts">
                <p>Nenhum post publicado ainda.</p>
              </div>
            ) : filteredPosts.length === 0 ? (
              <div className="no-results">
                <p>Nenhum resultado encontrado para <span className="search-term">"{searchTerm}"</span></p>
              </div>
            ) : (
              <div className="posts-list-container">
                {filteredPosts.map((post) => (
                  <div key={post.id} className="post-item">
                    <div className="post-info">
                      <h3>{post.title}</h3>
                      <div className="post-meta-info">
                        <span className="post-author">{post.author || 'Escritório Jurídico'}</span>
                        <span className="post-date">{formatPostDate(post.date)}</span>
                      </div>
                      {renderContentPreview(post.content)}
                    </div>
                    <div className="post-actions">
                      <button 
                        className="edit-button" 
                        onClick={() => handleEdit(post)}
                      >
                        Editar
                      </button>
                      <button 
                        className="delete-button" 
                        onClick={() => handleDelete(post.id!)}
                      >
                        Excluir
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
