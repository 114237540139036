import { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { WordPressService } from '../services/wordpressApi'

// Instrução para alteração do vídeo pelo WordPress:
// No WordPress, adicione o texto videourl: seguido do link do YouTube na seção "Resumo" da página.
// Exemplo: videourl: https://www.youtube.com/watch?v=suaCodiLink
// O vídeo será automaticamente exibido na seção "Nossa História".

interface AboutContent {
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
}

// Função para extrair o ID do vídeo de uma URL do YouTube
const extractYouTubeId = (url: string): string | null => {
  try {
    console.log('Extraindo ID do YouTube da URL:', url);
    
    // Padrões de URL do YouTube
    const patterns = [
      /(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([a-zA-Z0-9_-]{11})/,
      /[?&]v=([a-zA-Z0-9_-]{11})/
    ];
    
    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        console.log('ID do YouTube encontrado:', match[1]);
        return match[1];
      }
    }
    
    console.warn('Não foi possível extrair o ID do YouTube da URL:', url);
    return null;
  } catch (error) {
    console.error('Erro ao extrair ID do YouTube:', error);
    return null;
  }
};

const About = () => {
  const [aboutContent, setAboutContent] = useState<AboutContent | null>(null)
  const [videoId, setVideoId] = useState<string>('F9U5QPGiKww') // ID padrão como fallback
  // @ts-ignore: loading é usado internamente no fluxo de carregamento
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      disable: 'mobile' // Desativar em dispositivos móveis
    })
    loadAboutContent()
  }, [])

  const loadAboutContent = async () => {
    setLoading(true);
    try {
      const content = await WordPressService.getPage('sobre')
      setAboutContent(content)
      
      // Extrair URL do vídeo do conteúdo do WordPress
      if (content && content.excerpt && content.excerpt.rendered) {
        console.log('Conteúdo bruto recebido:', content.excerpt.rendered);
        
        // Decodificar entidades HTML como &amp; para & 
        const decodedExcerpt = document.createElement('div');
        decodedExcerpt.innerHTML = content.excerpt.rendered;
        const plainText = decodedExcerpt.textContent || '';
        
        console.log('Texto decodificado para busca:', plainText);
        
        // Método 1: Buscar pela URL com regex mais amplo
        const videoUrlMatch = plainText.match(/videourl:\s*(https?:\/\/[^\s\n]+)/i);
        
        if (videoUrlMatch && videoUrlMatch[1]) {
          // Pegar apenas a URL, removendo qualquer texto adicional
          let extractedUrl = videoUrlMatch[1].trim();
          
          // Remover qualquer texto após espaço ou quebra de linha
          if (extractedUrl.includes(' ')) {
            extractedUrl = extractedUrl.split(' ')[0];
          }
          
          // Remover qualquer HTML que possa estar no final da URL
          extractedUrl = extractedUrl.replace(/<\/?[^>]+(>|$)/g, "");
          
          console.log('About - URL do vídeo extraída:', extractedUrl);
          
          // Extrair o ID do vídeo a partir da URL
          const extractedId = extractYouTubeId(extractedUrl);
          if (extractedId) {
            console.log('ID do vídeo definido para:', extractedId);
            setVideoId(extractedId);
          }
        } else {
          console.log('About - Regex principal não encontrou match, tentando busca direta');
          
          // Método alternativo: buscar diretamente por um link do YouTube
          const youtubeMatch = plainText.match(/(https?:\/\/(?:www\.)?youtube\.com\/watch\?v=[^\s\n&]+|https?:\/\/youtu\.be\/[^\s\n?]+)/i);
          
          if (youtubeMatch && youtubeMatch[0]) {
            console.log('About - URL do YouTube encontrada diretamente:', youtubeMatch[0]);
            const extractedId = extractYouTubeId(youtubeMatch[0]);
            if (extractedId) {
              console.log('ID do vídeo definido para:', extractedId);
              setVideoId(extractedId);
            }
          }
        }
      }
    } catch (error) {
      console.error('Erro ao carregar conteúdo:', error);
    } finally {
      setLoading(false);
    }
  }

  // Garantir que o texto seja justificado após carregar o conteúdo
  useEffect(() => {
    if (aboutContent) {
      // Aguardar a renderização do conteúdo
      setTimeout(() => {
        // Selecionar todos os parágrafos na seção de história
        const paragraphs = document.querySelectorAll('.about .section-header p, .about-text-justified p');
        paragraphs.forEach(p => {
          (p as HTMLElement).style.textAlign = 'justify';
        });
      }, 100);
    }
  }, [aboutContent]);

  return (
    <section id="about" className="about">
      <div className="section-header">
        <span className="section-subtitle" data-aos="fade-up">Quem Somos</span>
        <h2 data-aos="fade-up" data-aos-delay="100">Nossa História</h2>
        <div 
          data-aos="fade-up" 
          data-aos-delay="200"
          className="about-text-justified"
          style={{ textAlign: 'justify' }}
          dangerouslySetInnerHTML={{ 
            __html: aboutContent?.content.rendered || `
              <p style="text-align: justify !important;">
                O Escritório Carneiro & Santos Advogados Associados foi fundado em 15 de maio de 2017, no coração da Grande Vitória, com o objetivo de oferecer serviços jurídicos de qualidade e personalizados.
                Nossa abordagem é centrada no cliente, valorizando a relação de confiança e transparência. Nossa equipe de profissionais qualificados atua em diversas áreas do direito, garantindo uma abordagem especializada e eficaz.
                Nossa missão é fornecer orientação jurídica confiável e eficiente, ajudando nossos clientes a alcançar seus objetivos. Mantemos um contato próximo e constante com nossos clientes, visando construir relacionamentos duradouros e de confiança.
                Nossa estrutura permite que ofereçamos serviços de advocacia preventiva, consultiva e contenciosa, garantindo tranquilidade e segurança para nossos clientes em suas atividades.
              </p>
            `
          }}
        />
      </div>
      <div className="about-content">
        <div className="about-cards">
          <div className="about-card" data-aos="fade-up" data-aos-delay="200">
            <i className="ri-shield-check-line"></i>
            <div className="about-card-content">
              <h3>Missão</h3>
              <p>Solucionar demandas jurídicas de forma eficiente e atenciosa, priorizando a confiança mútua entre advogado e cliente.</p>
            </div>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="400">
            <i className="ri-eye-line"></i>
            <div className="about-card-content">
              <h3>Visão</h3>
              <p>Ser um escritório de advocacia reconhecido pela presteza e competência no Estado do Espírito Santo.</p>
            </div>
          </div>
          <div className="about-card" data-aos="fade-up" data-aos-delay="600">
            <i className="ri-heart-line"></i>
            <div className="about-card-content">
              <h3>Valores</h3>
              <p>Transparência, honestidade e humanização no atendimento, garantindo a melhor experiência para nossos clientes.</p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Player de vídeo do YouTube - versão isolada e completamente interativa */}
      <div style={{ 
        margin: '2rem auto 4rem',
        maxWidth: '850px',
        position: 'relative',
        zIndex: 99999
      }}>
        <div 
          id="youtube-player-container" 
          className="youtube-wrapper" 
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'visible',
            paddingTop: '56.25%', /* 16:9 Aspect Ratio */
            background: '#000',
            borderRadius: '8px',
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
            zIndex: 99999,
            isolation: 'isolate'
          }}
        >
          <iframe
            id="youtube-iframe-player"
            src={`https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&controls=1&enablejsapi=0&fs=1&playsinline=1&showinfo=1&iv_load_policy=3&color=white`}
            className="youtube-iframe"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '8px',
              zIndex: 99999,
              pointerEvents: 'auto'
            }}
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            loading="lazy"
            referrerPolicy="origin"
            sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation allow-top-navigation"
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export default About
