import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './components/Home'
import Blog from './components/Blog'
import BlogPost from './components/BlogPost'
import Admin from './components/Admin'
import AdminDashboard from './components/AdminDashboard'
import ClientArea from './components/ClientArea'
import WhatsAppButton from './components/WhatsAppButton'
import MobileWhatsAppButton from './components/MobileWhatsAppButton'
import PrivateRoute from './components/PrivateRoute'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'remixicon/fonts/remixicon.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './App.css'
import './styles/responsive.css'
import { useGlobalScripts } from './hooks/useGlobalScripts'
import CreditoPage from './pages/services/CreditoPage'
import TrabalhistaPage from './pages/services/TrabalhistaPage'
import ConsumidorPage from './pages/services/ConsumidorPage'
import FamiliaPage from './pages/services/FamiliaPage'
import EmpresarialPage from './pages/services/EmpresarialPage'
import ImobiliarioPage from './pages/services/ImobiliarioPage'
import ContenciosoPage from './pages/services/ContenciosoPage'
import ServicePage from './pages/services/ServicePage'
import MobileNavbar from './components/MobileNavbar'

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Configuração responsiva do AOS
    AOS.init({
      duration: 800,
      offset: window.innerWidth <= 768 ? 10 : 100,
      once: true,
      disable: window.innerWidth <= 768 ? 'mobile' : false
    });

    // Reinicializa AOS quando o tamanho da tela muda
    const handleResize = () => {
      AOS.refresh();
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        
        if (scrollTop > 50) {
          navbar.classList.add('scroll-up');
        } else {
          navbar.classList.remove('scroll-up');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Limpa os event listeners quando o componente desmontar
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useGlobalScripts()

  return (
    <Router>
      <Routes>
        {/* Rota principal com layout animado */}
        <Route path="/" element={
          <div className="app">
            <WhatsAppButton />
            <MobileWhatsAppButton />
            {isMobile ? <MobileNavbar /> : <Navbar />}
            <main>
              <Home />
              {isMobile && <Footer />}
            </main>
            {!isMobile && <Footer />}
          </div>
        } />

        {/* Rotas administrativas com layout fixo */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postId" element={<BlogPost />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin-dashboard" element={
          <PrivateRoute>
            <AdminDashboard />
          </PrivateRoute>
        } />
        <Route path="/client-area" element={<ClientArea />} />
        <Route path="/servicos/credito" element={<CreditoPage />} />
        <Route path="/servicos/trabalhista" element={<TrabalhistaPage />} />
        <Route path="/servicos/consumidor" element={<ConsumidorPage />} />
        <Route path="/servicos/familia" element={<FamiliaPage />} />
        <Route path="/servicos/empresarial" element={<EmpresarialPage />} />
        <Route path="/servicos/imobiliario" element={<ImobiliarioPage />} />
        <Route path="/servicos/contencioso" element={<ContenciosoPage />} />
        
        {/* Rota genérica para qualquer nova área de atuação */}
        <Route path="/servicos/:slug" element={<ServicePage />} />
      </Routes>
    </Router>
  )
}

export default App
