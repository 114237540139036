import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../styles/services.css'
import { WordPressService } from '../services/wordpressApi'

// Interface para a estrutura de uma área de atuação
interface ServiceArea {
  id: number;
  slug: string;
  title: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  featured_media_url?: string;
  meta?: {
    icone?: string;
    pageUrl?: string | null;
  };
}

// Dados padrão para fallback
const defaultServiceAreas = [
  {
    id: 1,
    slug: "credito",
    title: { rendered: "Cobrança e Recuperação de Crédito" },
    excerpt: { rendered: "Soluções eficientes para recuperação de valores" },
    meta: { icone: "ri-money-dollar-circle-line" }
  },
  {
    id: 2,
    slug: "trabalhista",
    title: { rendered: "Direito Trabalhista" },
    excerpt: { rendered: "Proteção e orientação nas relações de trabalho" },
    meta: { icone: "ri-team-line" }
  },
  {
    id: 3,
    slug: "consumidor",
    title: { rendered: "Direito do Consumidor" },
    excerpt: { rendered: "Defesa dos direitos nas relações de consumo" },
    meta: { icone: "ri-user-heart-line" }
  },
  {
    id: 4,
    slug: "familia",
    title: { rendered: "Família e Sucessões" },
    excerpt: { rendered: "Suporte em questões familiares e hereditárias" },
    meta: { icone: "ri-home-heart-line" }
  },
  {
    id: 5,
    slug: "empresarial",
    title: { rendered: "Direito Empresarial" },
    excerpt: { rendered: "Assessoria jurídica para empresas" },
    meta: { icone: "ri-building-line" }
  },
  {
    id: 6,
    slug: "imobiliario",
    title: { rendered: "Direito Imobiliário" },
    excerpt: { rendered: "Suporte em transações e questões imobiliárias" },
    meta: { icone: "ri-home-line" }
  }
];

// Função para extrair ícone do excerpt da postagem
const extractIconFromExcerpt = (excerpt: string): string => {
  const match = excerpt.match(/icone:([a-zA-Z0-9-]+)/);
  return match ? match[1].trim() : "ri-briefcase-line"; // Ícone padrão
};

// Função para extrair URL da página do excerpt
const extractPageUrl = (excerpt: string): string | null => {
  const match = excerpt.match(/pagina:([^\n]+)/);
  if (!match) return null;
  
  let pageUrl = match[1].trim();
  
  // Remover qualquer tag HTML
  pageUrl = pageUrl.replace(/<[^>]*>/g, '');
  
  // Se for uma URL externa completa, retorna como está
  if (pageUrl.startsWith('http')) return pageUrl;
  
  // Se for um path começando com barra, remove a barra inicial para usar na rota interna
  if (pageUrl.startsWith('/')) {
    // Extrai apenas o slug final do path
    const parts = pageUrl.split('/').filter(Boolean);
    const slug = parts[parts.length - 1] || '';
    return slug;
  }
  
  // Se for apenas um slug, retorna como está
  return pageUrl;
};

// Limpar e normalizar o slug para URLs seguras
const normalizeSlug = (slug: string): string => {
  // Primeiro remover qualquer tag HTML
  let cleanSlug = slug.replace(/<[^>]*>/g, '');
  
  // Depois normalizar para um slug válido
  cleanSlug = cleanSlug
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9-]/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-|-$/g, '');
    
  return cleanSlug;
};

// Função para limpar texto HTML
const cleanHtmlText = (text: string): string => {
  return text
    .replace(/&nbsp;/g, ' ')
    .replace(/&#8211;/g, '-')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .trim();
};

const Services = () => {
  const [serviceAreas, setServiceAreas] = useState<ServiceArea[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    })
    
    // Função para carregar áreas de atuação do WordPress
    const loadServiceAreas = async () => {
      try {
        // Buscar posts da categoria "areas-de-atuacao"
        const response = await WordPressService.getBlogPosts();
        const services = response
          .filter(post => {
            // Verificar se o post tem "area-de-atuacao" no excerpt
            return post.excerpt.rendered.includes('area-de-atuacao');
          })
          .map(post => {
            // Extrair o ícone do excerpt
            const icone = extractIconFromExcerpt(post.excerpt.rendered);
            
            // Extrair URL da página específica, se existir
            const pageUrl = extractPageUrl(post.excerpt.rendered);
            
            // Extrair o slug do título ou da URL e garantir que está normalizado
            let slug = normalizeSlug(post.slug);
            
            // Se o slug estiver vazio, extrair do título
            if (!slug) {
              slug = normalizeSlug(post.title.rendered);
            }
            
            return {
              ...post,
              slug,
              meta: { 
                icone,
                pageUrl: pageUrl ? normalizeSlug(pageUrl) : null
              }
            };
          });
        
        if (services.length > 0) {
          setServiceAreas(services);
        } else {
          // Usar dados padrão se não encontrar nada
          setServiceAreas(defaultServiceAreas);
        }
      } catch (error) {
        console.error('Erro ao carregar áreas de atuação:', error);
        setServiceAreas(defaultServiceAreas);
      } finally {
        setLoading(false);
      }
    };
    
    loadServiceAreas();
  }, []);

  // Função para extrair o texto limpo do excerpt
  const getExcerptText = (excerptHtml: string): string => {
    // Remover a tag icone e pagina se existirem
    const cleanExcerpt = excerptHtml
      .replace(/<[^>]*>/g, '')
      .replace(/icone:[a-zA-Z0-9-]+/, '')
      .replace(/pagina:[^\n]+/, '')
      .replace(/area-de-atuacao/, '')
      .trim();
    
    return cleanHtmlText(cleanExcerpt) || "Clique para saber mais";
  };

  return (
    <section id="services" className="services">
      <div className="section-header">
        <span className="section-subtitle" data-aos="fade-up">Nossos Serviços</span>
        <h2 data-aos="fade-up" data-aos-delay="100">Áreas de Atuação</h2>
        <p data-aos="fade-up" data-aos-delay="200">Oferecemos serviços jurídicos especializados em diversas áreas</p>
      </div>
      
      {loading ? (
        <div className="loading-indicator">Carregando áreas de atuação...</div>
      ) : (
        <div className="services-grid">
          {serviceAreas.map((service, index) => {
            // Determinar a URL de destino do card
            // Se tiver uma URL de página específica e for uma URL completa, use-a
            // Caso contrário, use a rota padrão /servicos/slug
            const slug = service.meta?.pageUrl || service.slug;
            const targetUrl = slug.startsWith('http') 
              ? slug  // URL completa externa
              : `/servicos/${slug}`; // Rota interna React
            
            console.log(`Área de atuação: ${service.title.rendered}, URL: ${targetUrl}`);
            
            // Usar Link para rotas internas ou 'a' para URLs externas completas
            return targetUrl.startsWith('http') ? (
              <a 
                key={service.id} 
                href={targetUrl}
                className="service-card" 
                data-aos="zoom-in" 
                data-aos-delay={index * 200}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={service.meta?.icone || "ri-briefcase-line"}></i>
                <h3>{cleanHtmlText(service.title.rendered)}</h3>
                <p>{getExcerptText(service.excerpt.rendered)}</p>
                <span className="saiba-mais">Clique aqui e saiba mais</span>
              </a>
            ) : (
              <Link 
                key={service.id} 
                to={targetUrl} 
                className="service-card" 
                data-aos="zoom-in" 
                data-aos-delay={index * 200}
              >
                <i className={service.meta?.icone || "ri-briefcase-line"}></i>
                <h3>{cleanHtmlText(service.title.rendered)}</h3>
                <p>{getExcerptText(service.excerpt.rendered)}</p>
                <span className="saiba-mais">Clique aqui e saiba mais</span>
              </Link>
            );
          })}
        </div>
      )}
    </section>
  )
}

export default Services
