import { useEffect, useState } from 'react';
import '../styles/whatsapp-button.css';

const WhatsAppButton = () => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsHidden(false);
      } else {
        setIsHidden(true);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // Chama imediatamente para definir o estado inicial

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <a
      href="https://api.whatsapp.com/send?phone=5527988332711&text=Ol%C3%A1,%20gostaria%20de%20agendar%20um%20atendimento."
      className={`whatsapp-float ${isHidden ? 'hidden' : ''}`}
      target="_blank"
      rel="noopener noreferrer"
      title="Fale conosco pelo WhatsApp"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
};

export default WhatsAppButton;
