import { useEffect, useState } from 'react';
import '../styles/mobile-whatsapp-button.css';

const MobileWhatsAppButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const heroSection = document.querySelector('.hero');
    
    if (heroSection) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          // Mostra o botão apenas quando o hero não estiver visível
          setIsVisible(!entry.isIntersecting);
        },
        {
          threshold: 0.3, // Quando 30% do hero sair da tela
          rootMargin: '0px'
        }
      );

      observer.observe(heroSection);
      return () => observer.disconnect();
    }
  }, []);

  return (
    <a
      href="https://wa.me/5527988332711"
      className={`mobile-whatsapp-button ${isVisible ? 'visible' : ''}`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Contato WhatsApp"
    >
      <i className="ri-whatsapp-line"></i>
    </a>
  );
};

export default MobileWhatsAppButton; 