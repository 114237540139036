import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/navbar.css';
import ClientArea from './ClientArea';

const Navbar = () => {
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const navLinksRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLElement>(null);
  const [isClientAreaModalOpen, setIsClientAreaModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const closeClientAreaModal = () => {
    setIsClientAreaModalOpen(false);
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.querySelector(sectionId);
    if (section) {
      const navbarHeight = navbarRef.current?.offsetHeight || 0;
      const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionTop - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
    e.preventDefault();
    
    if (location.pathname === '/') {
      scrollToSection(sectionId);
    } else {
      navigate('/', { state: { scrollTo: sectionId } });
    }
    
    closeMenu();
  };

  const closeMenu = () => {
    const navLinks = navLinksRef.current;
    const hamburger = hamburgerRef.current;

    if (navLinks && hamburger) {
      navLinks.classList.remove('nav-active');
      hamburger.classList.remove('active');
      document.body.style.overflow = '';
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      const navbar = navbarRef.current;
      
      if (navbar) {
        if (currentScroll > 50) {
          navbar.classList.add('scroll-up');
        } else {
          navbar.classList.remove('scroll-up');
        }
      }
    };

    const toggleMenu = (e: Event) => {
      e.stopPropagation();
      const navLinks = navLinksRef.current;
      const hamburger = hamburgerRef.current;

      if (navLinks && hamburger) {
        navLinks.classList.toggle('nav-active');
        hamburger.classList.toggle('active');
        
        if (navLinks.classList.contains('nav-active')) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    const hamburger = hamburgerRef.current;
    if (hamburger) {
      hamburger.addEventListener('click', toggleMenu as EventListener);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (hamburger) {
        hamburger.removeEventListener('click', toggleMenu as EventListener);
      }
    };
  }, []);

  // Efeito para lidar com a navegação após redirecionamento
  useEffect(() => {
    if (location.pathname === '/' && location.state && (location.state as any).scrollTo) {
      const sectionId = (location.state as any).scrollTo;
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
    }
  }, [location]);

  return (
    <nav ref={navbarRef} className="navbar">
      <Link to="/" className="logo">
        <img src="/carneiro.png" alt="Carneiro & Santos Logo" className="logo-initial" />
        <img src="/logo2.png" alt="Carneiro & Santos Logo" className="logo-scroll" />
      </Link>
      <div 
        ref={hamburgerRef}
        className="hamburger"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div 
        ref={navLinksRef}
        className="nav-links"
      >
        <a href="#home" className="nav-link" onClick={(e) => handleNavigation(e, '#home')}>Início</a>
        <a href="#about" className="nav-link" onClick={(e) => handleNavigation(e, '#about')}>Sobre</a>
        <a href="#services" className="nav-link" onClick={(e) => handleNavigation(e, '#services')}>Serviços</a>
        <a href="#team" className="nav-link" onClick={(e) => handleNavigation(e, '#team')}>Equipe</a>
        <a href="#contact" className="nav-link" onClick={(e) => handleNavigation(e, '#contact')}>Contato</a>
        <Link to="/blog" className="nav-link">Blog</Link>
        <ClientArea 
          isModalOpenExternal={isClientAreaModalOpen}
          onCloseModalExternal={closeClientAreaModal}
        />
      </div>
    </nav>
  );
};

export default Navbar;
