import { useEffect } from 'react';

export const useGlobalScripts = () => {
  const resetBodyScroll = () => {
    const scrollY = document.body.style.top;
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  // Função de scroll movida para o escopo global do hook
  const handleScroll = () => {
    const navbar = document.querySelector('.navbar');
    const currentScroll = window.scrollY;
    
    if (currentScroll > 0) {
      navbar?.classList.add('scroll-up');
    } else {
      navbar?.classList.remove('scroll-up');
    }
  };

  // Efeito para controlar o scroll da navbar
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    // Chama uma vez para definir o estado inicial
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Menu mobile
    const hamburger = document.querySelector('.hamburger');
    const navLinks = document.querySelector('.nav-links');
    const links = document.querySelectorAll('.nav-link');

    // Adiciona os event listeners
    window.addEventListener('resize', handleScroll);

    if (hamburger && navLinks) {
      // Close menu when clicking outside
      document.addEventListener('click', (e) => {
        if (navLinks.classList.contains('nav-active') && 
            !navLinks.contains(e.target as Node) && 
            !hamburger.contains(e.target as Node)) {
          navLinks.classList.remove('nav-active');
          hamburger.classList.remove('active');
          resetBodyScroll();
        }
      });

      hamburger.addEventListener('click', (e) => {
        e.stopPropagation();
        navLinks.classList.toggle('nav-active');
        hamburger.classList.toggle('active');
        
        if (navLinks.classList.contains('nav-active')) {
          document.body.style.overflow = 'hidden';
          document.body.style.position = 'fixed';
          document.body.style.width = '100%';
          document.body.style.top = `-${window.scrollY}px`;
        } else {
          const scrollY = document.body.style.top;
          document.body.style.overflow = '';
          document.body.style.position = '';
          document.body.style.width = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
      });

      // Close menu when a link is clicked
      links.forEach(link => {
        link.addEventListener('click', () => {
          const scrollY = document.body.style.top;
          navLinks.classList.remove('nav-active');
          hamburger.classList.remove('active');
          document.body.style.overflow = '';
          document.body.style.position = '';
          document.body.style.width = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });
      });

      // WhatsApp Float Button Visibility
      const whatsappFloat = document.querySelector('.whatsapp-float') as HTMLElement;
      const heroSection = document.querySelector('.hero') as HTMLElement;
      const hamburgerMenu = document.querySelector('.hamburger-menu');
      const body = document.body;

      const toggleWhatsappButton = () => {
        if (heroSection && whatsappFloat) {
          const heroHeight = heroSection.offsetHeight || 0;
          const scrollPosition = window.scrollY;

          if (scrollPosition < heroHeight) {
            whatsappFloat.style.display = 'none';
          } else {
            whatsappFloat.style.display = 'flex';
          }
        }
      };

      window.addEventListener('scroll', toggleWhatsappButton);
      toggleWhatsappButton();

      if (hamburgerMenu) {
        hamburgerMenu.addEventListener('click', () => {
          body.classList.toggle('menu-open');
        });
      }

      // Smooth scroll para as seções
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (this: HTMLAnchorElement, e) {
          const href = this.getAttribute('href');
          if (href === '#') return;

          e.preventDefault();
          
          const targetId = href?.substring(1);
          const targetElement = document.getElementById(targetId || '');
          
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth'
            });
          }
        });
      });

      // Stats animation
      const statsSection = document.querySelector('.hero-stats');
      
      const animateValue = (element: Element, start: number, end: number, duration: number) => {
        let startTimestamp: number | null = null;
        const step = (timestamp: number) => {
          if (!startTimestamp) startTimestamp = timestamp;
          const progress = Math.min((timestamp - startTimestamp) / duration, 1);
          const value = Math.floor(progress * (end - start) + start);
          if (element instanceof HTMLElement) {
            element.textContent = value + ((element as HTMLElement).dataset.suffix || '');
          }
          if (progress < 1) {
            window.requestAnimationFrame(step);
          }
        };
        window.requestAnimationFrame(step);
      };

      const statsObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const statNumbers = entry.target.querySelectorAll('.stat-number');
            statNumbers.forEach(stat => {
              const value = parseInt(stat.textContent || '0');
              if (stat instanceof HTMLElement) {
                stat.dataset.suffix = '+';
              }
              animateValue(stat, 0, value, 2000);
            });
            statsObserver.unobserve(entry.target);
          }
        });
      }, {
        threshold: 0.5
      });

      if (statsSection) {
        statsObserver.observe(statsSection);
      }

      // Hero parallax effect
      const hero = document.querySelector('.hero') as HTMLElement;
      const handleParallax = () => {
        if (hero) {
          const scrolled = window.pageYOffset;
          hero.style.backgroundPositionY = scrolled * 0.5 + 'px';
        }
      };
      window.addEventListener('scroll', handleParallax);

      // Cleanup function
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('scroll', toggleWhatsappButton);
        window.removeEventListener('scroll', handleParallax);
        window.removeEventListener('resize', handleScroll);
      };
    }
  }, []);
};
