import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/mobile-navbar.css';
import ClientArea from './ClientArea';

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isClientAreaModalOpen, setIsClientAreaModalOpen] = useState(false);

  useEffect(() => {
    const heroSection = document.querySelector('.hero');
    
    if (heroSection) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          console.log('Hero visível:', entry.isIntersecting);
          setIsScrolled(!entry.isIntersecting);
        },
        {
          threshold: 0.3, // Quando 30% do hero sair da tela
          rootMargin: '0px'
        }
      );

      observer.observe(heroSection);
      return () => observer.disconnect();
    }
  }, []);

  const handleNavigation = (e: React.MouseEvent, sectionId: string) => {
    e.preventDefault();
    closeMenu();
    
    setTimeout(() => {
      const element = document.querySelector(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  const closeClientAreaModal = () => {
    setIsClientAreaModalOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? 'hidden' : '';
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = '';
  };

  return (
    <nav className={`mobile-navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="mobile-navbar-container">
        <Link to="/" className="mobile-logo" onClick={closeMenu}>
          <img src="/carneiro.png" alt="Logo" className="mobile-logo-img" />
        </Link>
        
        <button 
          className={`hamburger ${isOpen ? 'active' : ''}`} 
          onClick={toggleMenu}
          aria-label="Menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      <div className={`mobile-menu ${isOpen ? 'active' : ''}`}>
        <div className="mobile-menu-container">
          <a href="#home" onClick={(e) => handleNavigation(e, '#home')}>INÍCIO</a>
          <a href="#about" onClick={(e) => handleNavigation(e, '#about')}>SOBRE</a>
          <a href="#services" onClick={(e) => handleNavigation(e, '#services')}>SERVIÇOS</a>
          <a href="#team" onClick={(e) => handleNavigation(e, '#team')}>EQUIPE</a>
          <a href="#contact" onClick={(e) => handleNavigation(e, '#contact')}>CONTATO</a>
          <Link to="/blog" className="nav-link">Blog</Link>
          <ClientArea 
            isModalOpenExternal={isClientAreaModalOpen}
            onCloseModalExternal={closeClientAreaModal}
          />
        </div>
      </div>
    </nav>
  );
};

export default MobileNavbar; 