import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  collection, 
  getDocs, 
  query, 
  orderBy, 
  doc, 
  getDoc, 
  updateDoc 
} from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/blog.css';

const Blog = () => {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const calculateReadingTime = (content: string) => {
    // Remover marcadores de parágrafo e links
    const cleanContent = content
      .replace(/\|\|PARAGRAPH\|\|/g, ' ')
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1')
      .replace(/(https?:\/\/[^\s]+)/g, '');
    
    // Contar palavras
    const wordCount = cleanContent.trim().split(/\s+/).length;
    
    // Calcular tempo de leitura (250 palavras por minuto)
    const readingTimeMinutes = Math.ceil(wordCount / 250);
    
    return readingTimeMinutes;
  };

  // Função reservada para uso futuro
  // @ts-expect-error - Função será utilizada no futuro
  const __incrementViewCount = async (postId: string) => {
    try {
      console.log('Incrementando visualizações para post ID:', postId);
      const postRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postRef);
      
      if (postDoc.exists()) {
        const currentViews = postDoc.data().views || 0;
        await updateDoc(postRef, {
          views: currentViews + 1
        });
        console.log('Visualizações incrementadas com sucesso');
      }
    } catch (error) {
      console.error('Erro ao incrementar visualizações:', error);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const q = query(collection(db, 'posts'), orderBy('date', 'desc'));
        const querySnapshot = await getDocs(q);
        const postsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date?.toDate?.() || new Date(doc.data().date),
          views: doc.data().views || 0
        }));
        console.log('Fetched Posts:', postsData);
        setPosts(postsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Erro ao carregar as postagens. Por favor, tente novamente.');
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handleGoBack = () => {
    navigate('/');
  };

  const renderLinkInContent = (content: string) => {
    // Converter os marcadores de parágrafo para HTML preservando a formatação
    const htmlContent = content
      .replace(/\|\|PARAGRAPH\|\|/g, ' ')
      // Remover tags <div> extras que possam causar problemas de formatação
      .replace(/<div[^>]*>(.*?)<\/div>/g, '$1')
      // Garantir que as tags <script> sejam removidas por segurança
      .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
      // Substituir as tags <span> por conteúdo direto para economizar espaço
      .replace(/<span[^>]*>(.*?)<\/span>/g, '$1');
    
    // Limpar o HTML para exibição, removendo formatação desnecessária
    const cleanHtml = htmlContent
      // Remover atributos de data
      .replace(/data-[^=]*="[^"]*"/g, '')
      // Remover classes e IDs desnecessários
      .replace(/class="[^"]*"/g, '')
      .replace(/id="[^"]*"/g, '')
      // Remover atributos de estilo inline 
      .replace(/style="[^"]*"/g, '');
    
    return (
      <div className="content-preview" dangerouslySetInnerHTML={{ __html: cleanHtml }} />
    );
  };

  return (
    <div className="blog-container">
      <div className="blog-content">
        <div className="blog-header">
          <div className="blog-header-left">
            <button className="back-button" onClick={handleGoBack}>
              <i className="ri-arrow-left-line"></i>
            </button>
            <div className="blog-header-title">
              <h1>Blog Jurídico</h1>
              <p>Artigos e notícias sobre direito e advocacia</p>
            </div>
          </div>
          <img 
            src="/logo.png" 
            alt="Carneiro e Santos Advocacia" 
            className="blog-header-logo" 
          />
        </div>
        
        <div className="posts-container">
          {loading ? (
            <div className="loading">Carregando posts...</div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : (
            <>
              <div className="posts-grid">
                {posts.map((post) => {
                  const readingTime = calculateReadingTime(post.content);
                  return (
                    <Link 
                      to={`/blog/${post.id}`} 
                      key={post.id}
                      className="post-card-link"
                    >
                      <article className="post-card">
                        <div className="post-meta">
                          <span className="author">{post.author}</span>
                          <span className="date">
                            {post.date.toLocaleDateString()}
                            <span className="reading-time">
                              • {readingTime} min de leitura
                            </span>
                          </span>
                        </div>
                        <h2>{post.title}</h2>
                        <div className="post-excerpt">
                          {renderLinkInContent(post.content.substring(0, 350))}...
                        </div>
                        <div className="post-footer">
                          <span className="read-more">Ler mais</span>
                          <span className="view-count">
                            <i className="ri-eye-line"></i> {post.views || 0}
                          </span>
                        </div>
                      </article>
                    </Link>
                  );
                })}
              </div>
              
              {posts.length > 0 && (
                <div className="recent-posts">
                  <h2>Posts Recentes</h2>
                  <div className="recent-posts-grid">
                    {posts.slice(0, 3).map((post) => {
                      const readingTime = calculateReadingTime(post.content);
                      return (
                        <Link 
                          to={`/blog/${post.id}`} 
                          key={post.id}
                          className="recent-post-link"
                        >
                          <div className="recent-post-card">
                            <h3>{post.title}</h3>
                            <div className="recent-post-meta">
                              <span className="date">
                                {post.date.toLocaleDateString()}
                                <span className="reading-time">
                                  • {readingTime} min de leitura
                                </span>
                              </span>
                            </div>
                            <div className="recent-post-content">
                              {renderLinkInContent(post.content.substring(0, 200))}...
                            </div>
                            <div className="recent-post-footer">
                              <span className="continue-reading">Continuar lendo</span>
                              <span className="view-count">
                                <i className="ri-eye-line"></i> {post.views || 0}
                              </span>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
