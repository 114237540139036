import { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { WordPressService, CATEGORIAS } from '../services/wordpressApi'
import '../styles/team.css'

interface TeamMember {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  featured_media_url: string;
  categories: number[];
  excerpt: {
    rendered: string;
  };
}

// Dados de fallback para garantir que a seção sempre seja renderizada
const FALLBACK_MEMBERS: TeamMember[] = [
  {
    id: 1,
    title: { rendered: "Bernardo Oliveira Carneiro" },
    content: { rendered: "<p class='oab'>OAB/ES 24.697</p><p class='cargo'>Sócio</p><div class='mini-curriculum'><p><strong>Graduação:</strong> 2014</p><p><strong>Pós-Graduação:</strong> Direito Digital e Compliance - 2021 - Damásio Educacional</p></div>" },
    featured_media_url: "/sample-avatar.jpg",
    categories: [CATEGORIAS.SOCIOS],
    excerpt: { rendered: "" }
  },
  {
    id: 2,
    title: { rendered: "Ana Carolina Santos Corrêa" },
    content: { rendered: "<p class='oab'>OAB/ES 34.513</p><p class='cargo'>Associada</p><div class='mini-curriculum'><p><strong>Graduação:</strong> 2012</p><p><strong>Pós-Graduação:</strong> Direito Penal e Processo Penal - 2015</p></div>" },
    featured_media_url: "/sample-avatar.jpg",
    categories: [CATEGORIAS.ASSOCIADOS],
    excerpt: { rendered: "" }
  }
];

const Team = () => {
  const [socios, setSocios] = useState<TeamMember[]>([]);
  const [associados, setAssociados] = useState<TeamMember[]>([]);
  const [estagiarios, setEstagiarios] = useState<TeamMember[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [forceRender, setForceRender] = useState(0);
  
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    });
    loadTeamMembers();

    // Verificação adicional se os cards estão visíveis após o carregamento completo
    const timer = setTimeout(() => {
      const cards = document.querySelectorAll('.team-member');
      console.log(`Verificação após 2 segundos - cards visíveis: ${cards.length}`);
      if (cards.length === 0) {
        console.log('Forçando nova renderização...');
        setForceRender(prev => prev + 1);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Force render effect - tenta novamente se necessário
  useEffect(() => {
    if (forceRender > 0) {
      console.log(`Tentativa de re-renderização #${forceRender}`);
      
      // Se estamos na terceira tentativa e ainda não temos dados, use dados de fallback
      if (forceRender >= 3 && socios.length === 0 && associados.length === 0) {
        console.log('Usando dados de fallback após múltiplas tentativas');
        setSocios(FALLBACK_MEMBERS.filter(m => m.categories.includes(CATEGORIAS.SOCIOS)));
        setAssociados(FALLBACK_MEMBERS.filter(m => m.categories.includes(CATEGORIAS.ASSOCIADOS)));
        setLoading(false);
        setError(null);
      } else {
        // Tente carregar os dados novamente
        loadTeamMembers();
      }
    }
  }, [forceRender]);

  // Adicionar o efeito de flip via JavaScript
  useEffect(() => {
    if (!loading) {
      console.log("Cards carregados. Total:", document.querySelectorAll('.team-member').length);
    }
  }, [loading, socios, associados, estagiarios]);

  // Adicionar polyfill para dispositivos touch
  useEffect(() => {
    // Detectar se é um dispositivo mobile para log
    const isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    if (isMobile) {
      console.log("Detectado dispositivo móvel");
    }
  }, []);

  const loadTeamMembers = async () => {
    try {
      setError(null);
      console.log('Buscando membros da equipe...');
      const allMembers = await WordPressService.getTeamMembers();
      console.log('Membros carregados:', allMembers);
      
      if (!allMembers || allMembers.length === 0) {
        console.warn('Nenhum membro encontrado na API');
        setError('Não foi possível carregar os membros da equipe. Tente novamente mais tarde.');
        setLoading(false);
        return;
      }
      
      // Separar membros por categoria usando os IDs das categorias
      const foundSocios = allMembers.filter(member => 
        member.categories.includes(CATEGORIAS.SOCIOS)
      );
      
      const foundAssociados = allMembers.filter(member => 
        member.categories.includes(CATEGORIAS.ASSOCIADOS)
      );
      
      const foundEstagiarios = allMembers.filter(member => 
        member.categories.includes(CATEGORIAS.ESTAGIARIOS)
      );
      
      console.log('Sócios encontrados:', foundSocios.length);
      console.log('Associados encontrados:', foundAssociados.length);
      console.log('Estagiários encontrados:', foundEstagiarios.length);
      
      setSocios(foundSocios);
      setAssociados(foundAssociados);
      setEstagiarios(foundEstagiarios);
    } catch (error) {
      console.error('Erro ao carregar equipe:', error);
      setError('Erro ao carregar a equipe. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  const getMemberInfo = (content: string) => {
    const info = WordPressService.extractMemberInfo(content);
    return {
      oab: info.oab.replace(/&nbsp;/g, ' ').trim(),
      cargo: info.cargo.replace(/&nbsp;/g, ' ').trim(),
      curriculum: info.curriculum || ''
    };
  };

  if (loading) {
    return (
      <section id="team" className="team">
        <div className="section-header">
          <h2>Profissionais Especializados</h2>
        </div>
        <div className="loading-spinner">Carregando equipe...</div>
      </section>
    );
  }

  // Verificar se houve erro no carregamento
  if (error) {
    return (
      <section id="team" className="team">
        <div className="section-header">
          <h2>Profissionais Especializados</h2>
        </div>
        <div className="team-message">
          {error}
          <button 
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              background: 'var(--accent-gold)',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            onClick={() => {
              console.log('Forçando exibição com dados de fallback');
              setSocios(FALLBACK_MEMBERS.filter(m => m.categories.includes(CATEGORIAS.SOCIOS)));
              setAssociados(FALLBACK_MEMBERS.filter(m => m.categories.includes(CATEGORIAS.ASSOCIADOS)));
              setError(null);
            }}
          >
            Exibir Equipe
          </button>
        </div>
      </section>
    );
  }

  // Verificar se não foram encontrados membros da equipe
  if (socios.length === 0 && associados.length === 0 && estagiarios.length === 0) {
    return (
      <section id="team" className="team">
        <div className="section-header">
          <span className="section-subtitle">Nossa Equipe</span>
          <h2>Profissionais Especializados</h2>
          <p>Nenhum membro da equipe encontrado</p>
          <button 
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              background: 'var(--accent-gold)',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            onClick={() => {
              console.log('Forçando exibição com dados de fallback');
              setSocios(FALLBACK_MEMBERS.filter(m => m.categories.includes(CATEGORIAS.SOCIOS)));
              setAssociados(FALLBACK_MEMBERS.filter(m => m.categories.includes(CATEGORIAS.ASSOCIADOS)));
            }}
          >
            Exibir Equipe
          </button>
        </div>
      </section>
    );
  }

  const MemberCard = ({ member }: { member: TeamMember }) => {
    const memberInfo = getMemberInfo(member.content.rendered);
    const [flipped, setFlipped] = useState(false);
    const [imageFailed, setImageFailed] = useState(false);
    
    // Função para gerar cores aleatórias para avatares sem imagem
    const generateAvatarColor = (name: string) => {
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      
      const c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
        .padStart(6, '0');
      
      return `#${c}`;
    };
    
    const avatarColor = generateAvatarColor(member.title.rendered);
    const initials = member.title.rendered
      .split(' ')
      .map(n => n[0])
      .join('')
      .substring(0, 2);
    
    // Detectar se estamos em mobile para mostrar dica visual
    const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;
    
    // Tratamento para nomes muito longos no mobile
    const formatName = (name: string) => {
      if (isMobile) {
        const names = name.split(' ');
        
        // Para nomes muito longos com várias palavras
        if (names.length > 2) {
          // Priorizar o primeiro e último nome
          return `${names[0]} ${names[names.length-1]}`;
        }
        
        // Para dois nomes onde o sobrenome é muito longo
        if (names.length === 2) {
          const firstName = names[0];
          const lastName = names[1];
          
          // Se o sobrenome for muito longo
          if (lastName.length > 8) {
            // Manter o primeiro nome e usar apenas parte do sobrenome
            return `${firstName} ${lastName.substring(0, 8)}...`;
          }
          
          // Se o nome completo for muito longo
          if (name.length > 18) {
            // Reduzir o tamanho do texto para caber melhor
            return name;
          }
        }
      }
      return name;
    };

    // Manipulador de flip simplificado
    const handleFlip = () => {
      setFlipped(!flipped);
    };
    
    return (
      <div 
        className={`team-member ${flipped ? 'flipped' : ''}`}
        data-aos="fade-up"
        onClick={handleFlip}
      >
        {/* Dica visual sempre visível em dispositivos móveis */}
        {isMobile && (
          <div 
            style={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              zIndex: 5,
              color: 'var(--accent-gold)',
              fontSize: '0.8rem',
              backgroundColor: 'rgba(255,255,255,0.8)',
              padding: '2px 8px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            Toque para virar
          </div>
        )}
        
        <div className="team-card-inner">
          {/* Frente do card */}
          <div className="team-card-front">
            {member.featured_media_url && member.featured_media_url !== "/sample-avatar.jpg" && !imageFailed ? (
              <img 
                src={member.featured_media_url} 
                alt={`Foto de ${member.title.rendered}`}
                style={{ objectFit: 'cover', objectPosition: 'center' }}
                onError={() => {
                  console.error('Erro ao carregar imagem:', member.featured_media_url);
                  setImageFailed(true);
                }}
              />
            ) : (
              <div 
                className="avatar-fallback" 
                style={{
                  backgroundColor: avatarColor,
                  color: '#fff',
                  height: '75%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '3rem'
                }}
              >
                {initials}
              </div>
            )}
            <div className="team-card-info">
              <h3 title={member.title.rendered}>{formatName(member.title.rendered)}</h3>
              <p className="member-meta">{memberInfo.oab} | {memberInfo.cargo}</p>
            </div>
          </div>
          
          {/* Verso do card (currículo) */}
          <div className="team-card-back">
            <div className="team-card-curriculum">
              <h3>{member.title.rendered}</h3>
              {memberInfo.curriculum ? (
                <div dangerouslySetInnerHTML={{ __html: memberInfo.curriculum }} />
              ) : (
                <div className="curriculum-section">
                  <p>{memberInfo.oab}</p>
                  <p>{memberInfo.cargo}</p>
                  <p>Informações detalhadas indisponíveis</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="team" className="team">
      <div className="section-header">
        <span className="section-subtitle" data-aos="fade-up">Nossa Equipe</span>
        <h2 data-aos="fade-up" data-aos-delay="100">Profissionais Especializados</h2>
        <p data-aos="fade-up" data-aos-delay="200">Conheça nossa equipe</p>
      </div>
      
      <div className="team-grid">
        {socios.length > 0 && (
        <div className="team-category">
            <h3 data-aos="fade-up">SÓCIOS FUNDADORES</h3>
          <div className="team-members">
              {socios.map(member => (
                <MemberCard key={member.id} member={member} />
              ))}
            </div>
          </div>
        )}
        
        {associados.length > 0 && (
        <div className="team-category">  
            <h3 data-aos="fade-up">ADVOGADOS ASSOCIADOS</h3>
          <div className="team-members">
              {associados.map(member => (
                <MemberCard key={member.id} member={member} />
              ))}
            </div>
          </div>
        )}

        {estagiarios.length > 0 && (
        <div className="team-category">
            <h3 data-aos="fade-up">ESTAGIÁRIOS</h3>
          <div className="team-members">  
              {estagiarios.map(member => (
                <MemberCard key={member.id} member={member} />
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Team;
