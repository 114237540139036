import axios from 'axios';

const WP_API_URL = 'https://public-api.wordpress.com/wp/v2/sites/carneiroesantosadvadm.wordpress.com';

// Exportando as categorias
export const CATEGORIAS = {
  EQUIPE: 70121,
  SOCIOS: 159527,
  ASSOCIADOS: 1600383,
  ESTAGIARIOS: 703644
};

// Função para limpar texto HTML
const cleanHtmlText = (text: string): string => {
  return text
    .replace(/&nbsp;/g, ' ')
    .replace(/&#8211;/g, '-')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .trim();
};

interface WordPressPage {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  slug: string;
}

interface TeamMember {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  featured_media_url: string;
  categories: number[];
  excerpt: {
    rendered: string;
  };
}

interface ContactInfo {
  endereco: {
    rua: string;
    complemento: string;
    cidade: string;
  };
  telefones: {
    fixo: string;
    whatsapp: string;
  };
  horario: {
    dias: string;
    horas: string;
  };
  email: string;
  mapa: string;
}

export const WordPressService = {
  // Buscar uma página específica pelo slug
  getPage: async (slug: string): Promise<WordPressPage> => {
    try {
      const response = await axios.get(`${WP_API_URL}/pages?slug=${slug}`);
      if (response.data && response.data.length > 0) {
        return response.data[0];
      }
      throw new Error(`Página com slug "${slug}" não encontrada`);
    } catch (error) {
      console.error('Erro ao buscar página:', error);
      throw error;
    }
  },

  // Verificar se uma página existe
  pageExists: async (slug: string): Promise<boolean> => {
    try {
      const response = await axios.get(`${WP_API_URL}/pages?slug=${slug}`);
      return response.data && response.data.length > 0;
    } catch (error) {
      console.error('Erro ao verificar existência da página:', error);
      return false;
    }
  },

  // Buscar todas as páginas
  getAllPages: async (): Promise<WordPressPage[]> => {
    try {
      const response = await axios.get(`${WP_API_URL}/pages`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar páginas:', error);
      throw error;
    }
  },

  // Buscar posts do blog
  getBlogPosts: async (): Promise<WordPressPage[]> => {
    try {
      const response = await axios.get(`${WP_API_URL}/posts`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar posts:', error);
      throw error;
    }
  },

  // Nova implementação para equipe usando posts e categorias
  getTeamMembers: async (): Promise<TeamMember[]> => {
    try {
      // Buscar posts da categoria "equipe" usando o ID da categoria
      const response = await axios.get(`${WP_API_URL}/posts?categories=${CATEGORIAS.EQUIPE}&_embed`);
      return response.data.map((member: any) => {
        console.log('Dados brutos do membro:', member); // Debug
        
        // Usar jetpack_featured_media_url que é o campo correto no WordPress.com
        const mediaUrl = member.jetpack_featured_media_url || '';

        console.log('URL da imagem:', mediaUrl); // Debug

        return {
          ...member,
          title: {
            ...member.title,
            rendered: cleanHtmlText(member.title.rendered)
          },
          featured_media_url: mediaUrl
        };
      });
    } catch (error) {
      console.error('Erro ao buscar membros da equipe:', error);
      throw error;
    }
  },

  // Função para buscar as categorias disponíveis
  getCategories: async () => {
    try {
      const response = await axios.get(`${WP_API_URL}/categories`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
      throw error;
    }
  },

  // Função auxiliar para extrair informações do membro da equipe do conteúdo do post
  extractMemberInfo: (content: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    
    // Tentar encontrar o mini-currículo
    let curriculumHtml = '';
    
    try {
      // Primeira tentativa: buscar div com classe mini-curriculum
      const curriculumElement = doc.querySelector('.mini-curriculum');
      if (curriculumElement) {
        curriculumHtml = curriculumElement.innerHTML;
        console.log('Mini-currículo encontrado via classe:', curriculumHtml);
      } else {
        // Segunda tentativa: tentar extrair por conteúdo
        console.log('Buscando mini-currículo por conteúdo');
        
        // Buscar por text que contenha graduação, pós, ou ano
        const allElements = doc.querySelectorAll('p, div');
        let candidateElement = null;
        
        for (const el of allElements) {
          const text = el.textContent || '';
          if (
            text.includes('Graduação') || 
            text.includes('Pós') || 
            text.includes('OAB/ES') ||
            /Ano.*\d{4}/.test(text)
          ) {
            candidateElement = el;
            // Se encontrarmos um parágrafo com este texto, provavelmente é parte do mini-currículo
            // Vamos tentar encontrar o div pai que contém todas as informações
            let parent = el.parentElement;
            if (parent && parent.querySelectorAll('p').length > 1) {
              candidateElement = parent;
              break;
            }
          }
        }
        
        if (candidateElement) {
          // Se for um elemento p, tente usar o elemento pai que pode conter todo o currículo
          if (candidateElement.tagName.toLowerCase() === 'p' && candidateElement.parentElement) {
            const parent = candidateElement.parentElement;
            // Verificar se o pai tem vários parágrafos (indicando um currículo completo)
            if (parent.querySelectorAll('p').length > 1) {
              curriculumHtml = parent.innerHTML;
            } else {
              curriculumHtml = candidateElement.innerHTML;
            }
          } else {
            curriculumHtml = candidateElement.innerHTML;
          }
          console.log('Mini-currículo encontrado via conteúdo:', curriculumHtml);
        }
      }
    } catch (error) {
      console.error('Erro ao extrair mini-currículo:', error);
    }
    
    // Extrair OAB e cargo
    const oab = doc.querySelector('.oab')?.textContent || '';
    const cargo = doc.querySelector('.cargo')?.textContent || '';
    
    // Se não encontramos o currículo, mas temos OAB, podemos tentar montar um currículo mínimo
    if (!curriculumHtml && oab) {
      curriculumHtml = `<p><strong>${oab}</strong></p>`;
      if (cargo) {
        curriculumHtml += `<p><strong>Cargo:</strong> ${cargo}</p>`;
      }
    }
    
    return {
      oab,
      cargo,
      curriculum: curriculumHtml
    };
  },

  // Nova função para buscar dados de contato
  getContactInfo: async (): Promise<ContactInfo> => {
    try {
      const response = await axios.get(`${WP_API_URL}/pages?slug=contatos`);
      const data = response.data;

      if (!data || data.length === 0) {
        throw new Error('Página de contatos não encontrada');
      }

      const content = data[0].content.rendered;
      console.log('Conteúdo bruto da página:', content); // Debug

      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');

      // Função auxiliar para extrair texto de um elemento
      const getText = (selector: string): string => {
        const element = doc.querySelector(selector);
        const text = element ? cleanHtmlText(element.textContent || '') : '';
        console.log(`Buscando ${selector}:`, text); // Debug
        return text;
      };

      // Buscar o iframe do mapa
      const iframeElement = doc.querySelector('iframe');
      console.log('Elemento iframe encontrado:', iframeElement); // Debug

      let mapaUrl = '';
      if (iframeElement) {
        mapaUrl = iframeElement.getAttribute('src') || '';
        console.log('URL do mapa encontrada:', mapaUrl); // Debug
      } else {
        // Tentar encontrar o iframe de outras formas
        const iframeHtml = content.match(/<iframe[^>]*src=["']([^"']*)["'][^>]*>/);
        if (iframeHtml && iframeHtml[1]) {
          mapaUrl = iframeHtml[1];
          console.log('URL do mapa encontrada via regex:', mapaUrl); // Debug
        }
      }

      const contactInfo = {
        endereco: {
          rua: getText('.endereco-rua'),
          complemento: getText('.endereco-complemento'),
          cidade: getText('.endereco-cidade')
        },
        telefones: {
          fixo: getText('.telefone-fixo'),
          whatsapp: getText('.telefone-whatsapp')
        },
        horario: {
          dias: getText('.horario-dias'),
          horas: getText('.horario-horas')
        },
        email: getText('.email'),
        mapa: mapaUrl
      };

      console.log('Informações de contato processadas:', contactInfo); // Debug
      return contactInfo;

    } catch (error) {
      console.error('Erro ao buscar informações de contato:', error);
      throw error;
    }
  }
}; 