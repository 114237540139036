import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../styles/footer.css'
// import { WordPressService } from '../services/wordpressApi'

// Interface para a estrutura de uma área de atuação
interface ServiceArea {
  id: number;
  slug: string;
  title: {
    rendered: string;
  };
  excerpt?: {
    rendered: string;
  };
  jetpack_featured_media_url?: string; // Campo real da API do WordPress.com
  meta?: {
    icone?: string;
    pageUrl?: string | null;
  };
  link?: string; // URL completa do post no WordPress
}

// Função para limpar texto HTML
const cleanHtmlText = (text: string): string => {
  return text
    .replace(/<[^>]*>/g, '')  // Remove todas as tags HTML
    .replace(/&nbsp;/g, ' ')
    .replace(/&#8211;/g, '-')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .trim();
};

// Dados estáticos das principais áreas de atuação para o rodapé
const footerServiceAreas: ServiceArea[] = [
  {
    id: 1,
    slug: "empresarial",
    title: { rendered: "Direito Empresarial" }
  },
  {
    id: 2,
    slug: "administrativo",
    title: { rendered: "Direito Administrativo" }
  },
  {
    id: 3,
    slug: "civil",
    title: { rendered: "Direito Civil" }
  },
  {
    id: 4,
    slug: "familia",
    title: { rendered: "Família e Sucessões" }
  },
  {
    id: 5,
    slug: "imobiliario",
    title: { rendered: "Direito Imobiliário" }
  },
  {
    id: 6,
    slug: "credito",
    title: { rendered: "Cobrança e Recuperação de Crédito" }
  },
  {
    id: 7,
    slug: "consumidor",
    title: { rendered: "Direito do Consumidor" }
  },
  {
    id: 8,
    slug: "trabalhista",
    title: { rendered: "Direito do Trabalho" }
  }
];

// Função para extrair URL da página específica, se existir
// Esta função é mantida como comentário para referência futura
/* const extractPageUrl = (excerpt: string): string | null => {
  const match = excerpt.match(/pagina:([^\n]+)/);
  return match ? match[1].trim() : null;
}; */

const Footer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [serviceAreas] = useState<ServiceArea[]>(footerServiceAreas);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    })
    
    // Verificar se é dispositivo móvel
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Checar inicialmente e adicionar listener para redimensionamento
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [])

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, section: string) => {
    e.preventDefault()
    
    // Se já estiver na página inicial, apenas role para a seção
    if (location.pathname === '/') {
      const element = document.querySelector(section)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      // Se estiver em outra página, navegue para a página inicial + seção
      navigate('/')
      // Aguarde a navegação e então role para a seção
      setTimeout(() => {
        const element = document.querySelector(section)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
    }
  }

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-info">
          <div className="footer-logo">
            <img src="/carneiro.png" alt="Carneiro & Santos Logo" />
          </div>
          <p>Transformando desafios legais em soluções eficazes.</p>
          <div className="social-links">
            <a href="https://www.instagram.com/carneiroesantosadv/" className="social-icon" title="Instagram" target="_blank" rel="noopener noreferrer">
              <i className="ri-instagram-line"></i>
            </a>
            <a href="https://wa.me/5527988332711" className="social-icon" title="WhatsApp" target="_blank" rel="noopener noreferrer">
              <i className="ri-whatsapp-line"></i>
            </a>
          </div>
          
          {/* Link para áreas de atuação em dispositivos móveis */}
          {isMobile && (
            <div className="mobile-areas-link">
              <a href="#services" onClick={(e) => handleNavigation(e, '#services')}>
                Ver nossas áreas de atuação <i className="ri-arrow-right-line"></i>
              </a>
            </div>
          )}
        </div>
        <div className="footer-links">
          <div className="footer-section">
            <h4>Navegação</h4>
            <a href="#home" onClick={(e) => handleNavigation(e, '#home')}>Página Inicial</a>
            <a href="#about" onClick={(e) => handleNavigation(e, '#about')}>Sobre Nós</a>
            <a href="#services" onClick={(e) => handleNavigation(e, '#services')}>Serviços</a>
            <a href="#team" onClick={(e) => handleNavigation(e, '#team')}>Nossa Equipe</a>
            <a href="#contact" onClick={(e) => handleNavigation(e, '#contact')}>Contato</a>
            <Link to="/blog">Blog</Link>
          </div>
          <div className="footer-section areas-section">
            <h4>Áreas de Atuação</h4>
            {serviceAreas.map(service => {
              // Rota simplificada para as áreas de atuação
              const targetUrl = `/servicos/${service.slug}`;
              
              return (
                <Link 
                  key={service.id} 
                  to={targetUrl}
                  title={cleanHtmlText(service.title.rendered)}
                >
                  {cleanHtmlText(service.title.rendered)}
                </Link>
              );
            })}
          </div>
          <div className="footer-section">
            <h4>Contato</h4>
            <a href="tel:+552732372711">
              <i className="ri-phone-line"></i> (27) 3237-2711
            </a>
            <a href="tel:+5527988332711">
              <i className="ri-phone-line"></i> (27) 98833-2711
            </a>
            <a href="mailto:contato@carneirosantos.adv.br">
              <i className="ri-mail-line"></i> contato@carneirosantos.adv.br
            </a>
            <a href="https://maps.app.goo.gl/U2oLwfTcMUBi5DSN7" target="_blank" rel="noopener noreferrer">
              <i className="ri-map-pin-line"></i> Enseada do Suá, Vitória/ES
            </a>
            <a href="#" rel="noopener noreferrer">
              <i className="ri-time-line"></i> Seg-Sex: 09h às 18h
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Carneiro & Santos Advogados Associados - Todos os direitos reservados</p>
      </div>
    </footer>
  )
}

export default Footer
