import { useEffect, useState, FormEvent, ChangeEvent } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { WordPressService } from '../services/wordpressApi'
import '../styles/contact.css'
import emailjs from '@emailjs/browser'

interface ContactInfo {
  endereco: {
    rua: string
    complemento: string
    cidade: string
  }
  telefones: {
    fixo: string
    whatsapp: string
  }
  horario: {
    dias: string
    horas: string
  }
  email: string
  mapa: string
}

interface FormData {
  name: string;
  phone: string;
  email: string;
  demandType: string;
  message: string;
}

const Contact = () => {
  const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null)
  const [formData, setFormData] = useState<FormData>({
    name: '',
    phone: '',
    email: '',
    demandType: '',
    message: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    })
    loadContactInfo()
  }, [])

  const loadContactInfo = async () => {
    try {
      const info = await WordPressService.getContactInfo()
      setContactInfo(info)
    } catch (error) {
      console.error('Erro ao carregar informações de contato:', error)
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { id, value } = e.target
    setFormData(prev => ({
      ...prev,
      [id]: value
    }))
    
    // Log para debug
    if (id === 'demandType') {
      console.log(`Tipo de Demanda selecionado: ${value}`);
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    setSubmitStatus('idle')
    setErrorMessage('')

    try {
      // Valores necessários para configurar o EmailJS
      // Você precisará se cadastrar em emailjs.com e obter suas próprias chaves
      const serviceId = 'carneirosantos'; // Substitua pelo seu Service ID do EmailJS
      const templateId = 'template_huksnah'; // Substitua pelo seu Template ID do EmailJS
      const publicKey = 'jQNJ2SIxK0-msC9Vy'; // Substitua pela sua Public Key do EmailJS

      // Preparando os dados para o template do EmailJS
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        from_phone: formData.phone,
        demand_type: formData.demandType,
        message: formData.message,
        reply_to: formData.email,
      };

      // Enviando o e-mail
      const response = await emailjs.send(serviceId, templateId, templateParams, publicKey);
      
      console.log('Email enviado com sucesso!', response);
      setSubmitStatus('success');
      
      // Limpar formulário após envio bem-sucedido
      setFormData({
        name: '',
        phone: '',
        email: '',
        demandType: '',
        message: ''
      });
      
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);
      setSubmitStatus('error');
      setErrorMessage('Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente ou entre em contato por telefone.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <section id="contact" className="contact">
      <div className="section-header">
        <span className="section-subtitle" data-aos="fade-up">Contato</span>
        <h2 data-aos="fade-up" data-aos-delay="100">Fale Conosco</h2>
        <p data-aos="fade-up" data-aos-delay="200">Estamos prontos para atender sua necessidade jurídica</p>
      </div>
      <div className="contact-container">
        <div className="contact-form-wrapper" data-aos="fade-up">
          <form className="contact-form" onSubmit={handleSubmit}>
            {/* Status de envio */}
            {submitStatus === 'success' && (
              <div className="form-status success">
                <i className="ri-check-line"></i>
                <p>Mensagem enviada com sucesso! Entraremos em contato em breve.</p>
              </div>
            )}
            
            {submitStatus === 'error' && (
              <div className="form-status error">
                <i className="ri-error-warning-line"></i>
                <p>{errorMessage}</p>
              </div>
            )}

            {/* 1. Nome Completo */}
            <div className="form-group">
              <label htmlFor="name">Nome Completo</label>
              <input 
                type="text" 
                id="name" 
                placeholder="Seu nome completo" 
                required 
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            
            {/* 2. Telefone com Whatsapp */}
            <div className="form-group">
              <label htmlFor="phone">Telefone com Whatsapp</label>
              <input 
                type="tel" 
                id="phone" 
                placeholder="(27) 00000-0000" 
                required
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            
            {/* 3. E-mail */}
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input 
                type="email" 
                id="email" 
                placeholder="Seu melhor e-mail" 
                required 
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            
            {/* 4. Tipo de Demanda */}
            <div className="form-group">
              <label htmlFor="demandType">Tipo de Demanda</label>
              <select 
                id="demandType"
                required
                value={formData.demandType}
                onChange={handleInputChange}
                className={formData.demandType ? 'selected' : ''}
              >
                <option value="" disabled>Selecione uma área</option>
                <option value="Consumidor">Consumidor</option>
                <option value="Trabalhista">Trabalhista</option>
                <option value="Cível">Cível</option>
                <option value="Empresarial">Empresarial</option>
                <option value="Família">Família</option>
                <option value="Inventário">Inventário</option>
              </select>
            </div>
            
            {/* 5. Como podemos te ajudar? */}
            <div className="form-group">
              <label htmlFor="message">Como podemos te ajudar?</label>
              <textarea 
                id="message" 
                placeholder="Descreva sua necessidade..." 
                required
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            
            <button 
              type="submit" 
              className="btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <i className="ri-loader-4-line animate-spin"></i>
                  Enviando...
                </>
              ) : (
                <>
                  <i className="ri-send-plane-line"></i>
                  Enviar Mensagem
                </>
              )}
            </button>
          </form>
        </div>

        <div className="contact-info-wrapper" data-aos="fade-up">
          <div className="contact-card">
            <i className="ri-map-pin-line"></i>
            <div>
              <h3>Endereço</h3>
              <p>{contactInfo ? 
                `${contactInfo.endereco.rua}, ${contactInfo.endereco.complemento}, ${contactInfo.endereco.cidade}` : 
                'Rua José Alexandre Buaiz, 160, Sala 623 e 624, Ed London Office Tower, Enseada do Suá, Vitória/ES'}</p>
            </div>
          </div>
          <div className="contact-card">
            <i className="ri-phone-line"></i>
            <div>
              <h3>Telefones</h3>
              <p>{contactInfo?.telefones.fixo || '(27) 3237-2711'}</p>
              <p>{contactInfo?.telefones.whatsapp || '(27) 98833-2711'}</p>
            </div>
          </div>
          <div className="contact-card">
            <i className="ri-time-line"></i>
            <div>
              <h3>Horário de Atendimento</h3>
              <p>{contactInfo ? 
                `${contactInfo.horario.dias}: ${contactInfo.horario.horas}` : 
                'Segunda à sexta: 09:00 às 18:00'}</p>
            </div>
          </div>
          <div className="contact-card">
            <i className="ri-mail-line"></i>
            <div>
              <h3>E-mail</h3>
              <p>{contactInfo?.email || 'contato@carneirosantos.adv.br'}</p>
            </div>
          </div>
        </div>

        <div className="contact-map" data-aos="fade-up">
          <iframe 
            src={contactInfo?.mapa || "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3741.1231957813897!2d-40.2986246!3d-20.3119444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjDCsDE4JzQzLjAiUyA0MMKwMTcnNTUuMSJX!5e0!3m2!1spt-BR!2sbr!4v1625161234567!5m2!1spt-BR!2sbr"} 
            width="100%" 
            height="400" 
            style={{ border: 0 }} 
            allowFullScreen 
            loading="lazy"
            title="Localização do escritório"
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export default Contact
