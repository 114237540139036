import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Interface para as propriedades do componente PrivateRoute
interface PrivateRouteProps {
  children: React.ReactNode;
}

// Verificar se existe um token de autenticação no localStorage
const isAuthenticated = (): boolean => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation();
  const [checking, setChecking] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Verificar o estado de autenticação
    const auth = isAuthenticated();
    setAuthenticated(auth);
    setChecking(false);
  }, []);

  if (checking) {
    // Enquanto verifica a autenticação, mostra um loader
    return (
      <div className="auth-loading">
        <p>Verificando autenticação...</p>
      </div>
    );
  }

  // Se não estiver autenticado, redireciona para a página de login
  if (!authenticated) {
    console.log('Usuário não autenticado. Redirecionando para login');
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  // Se estiver autenticado, renderiza o componente filho (conteúdo protegido)
  return <>{children}</>;
};

export default PrivateRoute; 