import Hero from './Hero';
import About from './About';
import Services from './Services';
import Team from './Team';
import Contact from './Contact';

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <Team />
      <Contact />
    </>
  );
};

export default Home;
