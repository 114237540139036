import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/blogPost.css';
import HugerteEditor from './HugerteEditor';

// Interface para a estrutura de um post
interface Post {
  id: string;
  title: string;
  content: string;
  author: string;
  date: Date;
  imageUrl?: string;
  [key: string]: any; // Para permitir outras propriedades que possam existir
}

const BlogPost = () => {
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { postId } = useParams();
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);
  
  // Verificar se o usuário é administrador (simplificado)
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  useEffect(() => {
    const fetchPost = async () => {
      if (!postId) {
        setError('ID do post não encontrado');
        setLoading(false);
        return;
      }

      try {
        const postRef = doc(db, 'posts', postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          const postData = {
            id: postSnap.id,
            ...postSnap.data(),
            date: postSnap.data().date?.toDate?.() || new Date(postSnap.data().date)
          } as Post;
          
          console.log('Fetched Post:', postData);
          setPost(postData);
          
          // Preparar dados para edição
          setEditContent(desanitizeContent(postData.content));
          setEditTitle(postData.title);
          
          // Incrementar o contador de visualizações
          incrementViewCount(postId);
        } else {
          setError('Post não encontrado');
        }
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar post:', error);
        setError('Erro ao carregar o post. Por favor, tente novamente.');
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  // Função para incrementar o contador de visualizações
  const incrementViewCount = async (postId: string) => {
    try {
      console.log('Incrementando visualizações para post ID:', postId);
      
      // Usar o operador de incremento do Firestore para evitar problemas de concorrência
      // e garantir uma única atualização atômica
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, {
        views: increment(1)
      });
      
      console.log('Visualizações incrementadas com sucesso');
    } catch (error) {
      console.error('Erro ao incrementar visualizações:', error);
      // Não exibir o erro para o usuário, apenas logar
    }
  };

  const handleGoBack = () => {
    navigate('/blog');
  };

  const desanitizeContent = (text: string) => {
    // Reverter marcador de parágrafo para quebras de linha
    return text.replace(/\|\|PARAGRAPH\|\|/g, '\n');
  };

  const handleSaveChanges = async () => {
    if (!postId || !post) return;
    
    try {
      setIsSaving(true);
      
      // Obter o conteúdo atualizado do editor
      const content = editorRef.current ? editorRef.current.getContent() : editContent;
      
      // Garantir que os links no conteúdo sejam preservados
      let processedContent = content;
      
      // Não sanitizar links ou URLs - manter o HTML como está
      // Apenas substituir quebras de linha conforme necessário
      const sanitizedContent = processedContent.replace(/\n/g, '||PARAGRAPH||');
      
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, {
        title: editTitle,
        content: sanitizedContent,
        // Manter a data original
      });
      
      // Atualizar o post localmente
      setPost({
        ...post,
        title: editTitle,
        content: sanitizedContent
      });
      
      // Mostrar mensagem de sucesso
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
      
      // Sair do modo de edição
      setIsEditing(false);
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
      setError('Erro ao salvar alterações. Por favor, tente novamente.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditorChange = (content: string) => {
    setEditContent(content);
  };

  const processContent = (content: string) => {
    // Preservar a formatação HTML original sem adicionar elementos extras
    const htmlContent = content
      .replace(/\|\|PARAGRAPH\|\|/g, ' ')
      // Garantir que as tags <script> sejam removidas por segurança
      .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
      // Garantir que links sejam clicáveis mantendo atributos href intactos
      .replace(/<a\s+([^>]*)>/gi, (_match, attrs) => {
        // Preservar todos os atributos originais e adicionar target="_blank" e rel="noopener"
        return `<a ${attrs} target="_blank" rel="noopener noreferrer">`;
      });
    
    return (
      <div className="post-content" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
  };

  if (loading) {
    return (
      <div className="blog-post-container">
        <div className="loading">Carregando post...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="blog-post-container">
        <div className="error-message">{error}</div>
        <button onClick={handleGoBack} className="back-to-blog">Voltar para o Blog</button>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="blog-post-container">
        <div className="error-message">Post não encontrado</div>
        <button onClick={handleGoBack} className="back-to-blog">Voltar para o Blog</button>
      </div>
    );
  }

  return (
    <div className="blog-post-container">
      <div className="blog-post-content">
        <button className="back-button" onClick={handleGoBack}>
          <i className="ri-arrow-left-line"></i>
        </button>

        {isAdmin && (
          <div className="admin-controls">
            {isEditing ? (
              <>
                <button 
                  className="save-button" 
                  onClick={handleSaveChanges}
                  disabled={isSaving}
                >
                  {isSaving ? 'Salvando...' : 'Salvar'}
                </button>
                <button 
                  className="cancel-button" 
                  onClick={() => setIsEditing(false)}
                  disabled={isSaving}
                >
                  Cancelar
                </button>
              </>
            ) : (
              <button 
                className="edit-button" 
                onClick={() => setIsEditing(true)}
              >
                Editar Post
              </button>
            )}
          </div>
        )}

        {showSuccess && (
          <div className="success-message">
            Post atualizado com sucesso!
          </div>
        )}

        <article className="post-full">
          {isEditing ? (
            <div className="edit-form">
              <div className="form-group">
                <label htmlFor="post-title">Título</label>
                <input
                  id="post-title"
                  type="text"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  className="title-input"
                />
              </div>
              <div className="form-group">
                <label htmlFor="post-content">Conteúdo</label>
                <div className="editor-container">
                  <HugerteEditor
                    value={editContent}
                    onEditorChange={handleEditorChange}
                    ref={editorRef}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="post-header">
                <h1>{post.title}</h1>
                <div className="post-meta">
                  <span className="author">{post.author}</span>
                  <span className="date">{post.date.toLocaleDateString()}</span>
                </div>
              </div>

              <div className="post-body">
                {processContent(post.content)}
              </div>

              {post.imageUrl && (
                <div className="post-image">
                  <img src={post.imageUrl} alt={post.title} />
                </div>
              )}
            </>
          )}
        </article>
      </div>
    </div>
  );
};

export default BlogPost;
